import React, { useState } from 'react';
import '../css/RoleMaster.css';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { API } from '../api/API';

const RoleMaster = () => {
    const navigate = useNavigate();
    const [role, setRole] = useState('');
    const [permissions, setPermissions] = useState({
        bom_upload: false,
        label_print: false,
        label_reprint: false,
        assembly: false,
        fg: false,
        load_process: false,
        loading_slip_print: false,
        reports: false,
    });

    const api = new API();

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setPermissions((prev) => ({
            ...prev,
            [name]: checked,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Convert permissions object to an array of modules with true permissions
        const modules = Object.entries(permissions).reduce((acc, [key, value]) => {
            if (value) {
                acc.push({ module_name: key, [key]: true });
            }
            return acc;
        }, []);

        // Prepare data for API request
        const data = {
            role,
            modules, // Send the list of modules with true permissions
        };
        console.log(data);

        try {
            const response = await api.assign_role_permissions(data, navigate);
            console.log(response);
            // Handle successful response (e.g., show a success message or navigate)
        } catch (error) {
            console.error("Error assigning permissions:", error);
            // Handle error (e.g., show an error message)
        }
    };

    const handleBackClick = () => {
        navigate("/roles");
    };

    return (
        <div className="rolemaster">
            <form onSubmit={handleSubmit}>
                <div className='role'>
                    <label>
                        Role:
                        <input
                            type="text"
                            placeholder='Enter the role'
                            value={role}
                            onChange={(e) => setRole(e.target.value)}
                            required
                        />
                    </label>
                </div>
                <div className='modules'>
                    <table>
                        <thead>
                            <tr>
                                <th>S.no</th>
                                <th>Module Name</th>
                                <th>Permission</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(permissions).map((module, index) => (
                                <tr key={index}>
                                    <td>{index + 1}.</td>
                                    <td>{module.replace('_', ' ').toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</td>
                                    <td>
                                        <input
                                            type="checkbox"
                                            name={module}
                                            checked={permissions[module]}
                                            onChange={handleCheckboxChange}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div id="btn">
                    <Button
                            variant="dark"
                            onClick={handleBackClick}
                            className="rolemaster-back-btn"
                            size="sm"
                            style={{ marginLeft: "19px" }}
                        >
                            Back
                        </Button>
                        <button type="submit" className="rolemaster-savepermission-btn">Save Permissions</button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default RoleMaster;
