import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { API } from "../api/API";
import DataTable from 'react-data-table-component';
import Container from "react-bootstrap/esm/Container";

export default function LabelPrintIndex() {
  const text = "Label Print";
  const api = new API();

  const [productionOrders, setProductionOrders] = useState([]);
  // State for search terms
  const [filters, setFilters] = useState({
    productionOrderNumber: '',
    jobTransactionNumber: '',
    productionOrderQty: '',
    createdAt: '',
    createdBy: '',
    printStatus: '',
  });

  // Handle filter input changes
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));
  };

  // Filter data based on filters

  const filteredData1 = productionOrders.filter(row => row.status === 'active');


  const filteredData = filteredData1.filter(row =>
    row.production_order_number.toLowerCase().includes(filters.productionOrderNumber.toLowerCase()) &&
    row.job_transaction_number.toLowerCase().includes(filters.jobTransactionNumber.toLowerCase()) &&
    row.production_order_qty.toString().includes(filters.productionOrderQty) &&
    row.created_at.toString().includes(filters.createdAt)&&
    row.created_by.toString().includes(filters.createdBy) &&
    (row.print_status === "no" || row.print_status === 'partial' ? "Pending" : "Completed").toLowerCase().includes(filters.printStatus.toLowerCase())
  );
  useEffect(() => {
    async function get() {
      const data = await api.production_header_get();
      setProductionOrders(data);      
    }

    get();
  }, []);

  

  const columns = [
    {
      name: (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <span>S.No.</span>
          <input
            type="text"
            placeholder="S.No."
            // disabled
            style={{ marginTop: '3px',marginBottom:'2px', width: '100%', height:"20px",padding: '5px', textAlign: "center" }}
          />
          </div>
        </>
      ),
      selector: (row, index) => filteredData.length ? index + 1 : '',
      sortable: false,
      width: '70px'
    },
    {
      name: (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <span>Production Order Number</span>
            <input
              type="text"
              name="productionOrderNumber"
              value={filters.productionOrderNumber}
              onChange={handleFilterChange}
              placeholder="Production Order Number"
              style={{ marginTop: '3px',marginBottom:'2px', width: '100%',height:"20px", padding: '5px', textAlign: "center" }}
            />
          </div>
        </>
      ),
      selector: row => row.production_order_number,
      // sortable: true,
      width: '220px'
    },    
    {
      name: (
        <>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <span>Job Tracking Number</span>
          
          <input
            type="text"
            name="jobTransactionNumber"
            value={filters.jobTransactionNumber}
            onChange={handleFilterChange}
            placeholder="Job Tracking Number"
            style={{ marginTop: '3px',marginBottom:'2px', width: '100%', height:"20px",padding: '5px', textAlign: "center" }}
          />
          </div>
        </>
      ),
      selector: row => (
        <NavLink
          to="/label-print"
          state={{
            productionOrderID: row.id,
            productionOrderNumber: row.production_order_number,
            jobTransactionNumber: row.job_transaction_number,
            productionOrderQty: row.production_order_qty,
          }}
        >
          {row.job_transaction_number}
        </NavLink>
      ),
      // sortable: true,
      width: '220px'
    },
    {
      name: (
        <>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <span>Production Order Quantity</span>
          
          <input
            type="text"
            name="productionOrderQty"
            value={filters.productionOrderQty}
            onChange={handleFilterChange}
            placeholder="Production Order Quantity"
            style={{marginTop: '3px',marginBottom:'2px',  width: '100%',height:"20px", padding: '5px', textAlign: "center" }}
          />
          </div>
        </>
      ),
      selector: row => row.production_order_qty,
      // sortable: true,
      width: '220px'
    },
    {
      name: (
        <>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <span>Print Status</span>
          
          <input
            type="text"
            name="printStatus"
            value={filters.printStatus}
            onChange={handleFilterChange}
            placeholder="Print Status"
            style={{ marginTop: '3px',marginBottom:'2px', width: '100%',height:"20px", padding: '5px', textAlign: "center" }}
          />
          </div>
        </>
      ),
      selector: row => filteredData.length ? (row.print_status === "no" || row.print_status === 'partial' ? "Pending" : "Completed") : '',
      // sortable: true,
      width: '140px'
    },
    {
      name: (
        <>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <span>Created By</span>
          
          <input
            type="text"
            name="createdBy"
            value={filters.createdBy}
            onChange={handleFilterChange}
            placeholder="Created By"
            style={{marginTop: '3px',marginBottom:'2px',  width: '100%',height:"20px", padding: '5px', textAlign: "center" }}
          />
          </div>
        </>
      ),
      selector: row => row.created_by,
      // sortable: true,
      width: '150px'
    },
    {
      name: (
        <>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <span>Created At</span>
          
          <input
            type="text"
            name="createdAt"
            value={filters.createdAt}
            onChange={handleFilterChange}
            placeholder="Created At"
            style={{marginTop: '3px',marginBottom:'2px',  width: '100%',height:"20px", padding: '5px', textAlign: "center" }}
          />
          </div>
        </>
      ),
      selector: row => row.created_at,
      // sortable: true,
      width: '180px'
    },
  ];

  return (
    <div>
      <div className='basic' style={{ position: "relative", marginTop: "40px" }}>
      <Container 
      style={{ maxWidth: "max-content" }}
      >
          {/* Data Table with filters inside headers */}
          <DataTable
            className="data-table-container"
            columns={columns}
            data={filteredData.length ? filteredData : [{}]}
            
            pagination
            responsive
            highlightOnHover
            dense
          />
        </Container>
        
      </div>
    </div>
  );
}
