import { Link } from 'react-router-dom';
import '../css/NoAccessPage.css'
const NoAccessPage = () => {

    return (
        <div className="noaccesspage">
            <div class="container">
                <h1>4<div class="lock"><div class="top"></div><div class="bottom"></div>
                </div>3</h1><p>Access denied</p>
                <Link to='/'>Go back to DashBoard</Link>
            </div>
        </div>
    );
}

export default NoAccessPage;