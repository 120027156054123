import Table from "react-bootstrap/esm/Table";
import Container from "react-bootstrap/esm/Container";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { API } from "../api/API";
import Logo from "../images/logo2.png";

export default function ReportPrint() {
  const location = useLocation();
  const api = new API();

  const [data, setData] = useState({
    fg_sfg_data: [],
    boc_data: [],
    total_boc_count: "",
    pickslip_number: "",
    client_reference_project: [],
  });

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-11
  const day = String(currentDate.getDate()).padStart(2, '0');
  const formattedDate = `${day}-${month}-${year}`;

  const searchParams = new URLSearchParams(location.search);
  const job_transaction_id = searchParams.get('job_transaction_id');
  const tag = searchParams.get('tag');
  const sales_order_number = searchParams.get('sales_order_number');
  const production_order_number = searchParams.get('production_order_number');
  const job_transaction_number = searchParams.get('job_transaction_number');

  useEffect(() => {
    async function get_job_transaction_number() {
      const response = await api.reports_get_job_transaction_number(
        job_transaction_id
      );
      setData(response);
    }

    get_job_transaction_number();
  }, []);

  useEffect(() => {
    if (data.fg_sfg_data.length > 0) {
      window.print();
      window.close();
    }
  }, [data]);

  return (

    <div className="loadingslipprint">
      <div className="packing-list-container">
        <div className="header">
          <div className="logo">
            <img src={Logo} alt="Logo" style={{ width: '160px', height: '30px', position: 'relative' }} />
            <h1>LUFTEK ENGINEERING PVT LTD</h1>
          </div>
          <div className="company-details">

            <p>Plot No. A-1/1, Industrial Park - Ph II, Cheyyar-Kancheepuram Road, Kunnavakkam, Cheyyar SIPCOT, Tiruvannamalai District, Tamil Nadu - 631701.</p>
          </div>
        </div>

        <h2 style={{ textAlign: 'center', fontSize: '24px', margin: '5px', fontWeight: 'bold' }}>{tag} STATUS LIST</h2>

        <table className="packing-list-table">
          <tbody>
            <tr>
              <th>CLIENT</th>
              {data.client_reference_project[0] && <td colSpan='3'>{data.client_reference_project[0][0]}</td>}
              <th>DATE</th>
              <td>{formattedDate}</td>
            </tr>
            <tr>
              <th rowSpan='2'>PROJECT</th>
              {data.client_reference_project[0] && <td colSpan='3' rowSpan='2'>{data.client_reference_project[0][2]}</td>}
            </tr>
            <tr>
              <th>JTN No.</th>
              <td>{job_transaction_number}</td>
            </tr>
            <tr>
              <th>REFERENCE</th>
              {data.client_reference_project[0] && <td colSpan='3'>{data.client_reference_project[0][1]}</td>}
              <th>UNIT QTY.</th>
              <td>1</td>
            </tr>
            <tr>
              <th colSpan='3' style={{ textAlign: 'left' }}><span style={{ padding: ' 0 20px' }}>SALES ORDER -NO.</span>  <span style={{ paddingLeft: '40px' }}>{sales_order_number}</span></th>
              <th colSpan='3' style={{ textAlign: 'left' }}><span style={{ padding: ' 0 20px' }}>PRODUCTION ORDER -NO.</span> <span style={{ paddingLeft: '30px' }}>{production_order_number}</span></th>
            </tr>
          </tbody>
        </table>

        <table className="item-list-table">
          <thead>
            <tr>
              <th>SL. NO.</th>
              <th>PART NUMBER</th>
              <th>PART DESCRIPTION</th>
              {tag == 'ASSEMBLY' ? <th>ASSEMBLY STATUS</th> : tag == 'LOAD' ? <th>LOAD STATUS</th> : <th>PRODUCTION STATUS</th>}
            </tr>
          </thead>
          {data.fg_sfg_data.length > 0 && <tbody>
            {data.fg_sfg_data.map((item, index) => (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>{item.item_number}</td>
                <td>{item.item_description}</td>
                <td>
                  {tag === 'ASSEMBLY'
                    ? (item.repack_status === 'no' ? 'Pending' : 'Completed')
                    : tag === 'LOAD'
                      ? (item.load_status === 'no' ? 'Pending' : 'Completed')
                      : (item.inward_status === 'no' ? 'Pending' : 'Completed')
                  }
                </td>
              </tr>
            ))}
          </tbody>}
        </table>

        <div className="footer">
          <p>REMARKS - (PENDING FROM FACTORY):-</p>
          <p>Note: Please count the No. of packets and sign a copy of this packing list and send to factory for our records, if you find any discrepancy in the no. of packets.</p>
          <div className="signature-block">
            <div>PREPARED BY</div>
            <div>CHECKED BY</div>
            <div>RECEIVER NAME & SIGN</div>
          </div>
        </div>
      </div>
    </div>

  );
}
