import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Form, Button, Container, Row, Col, Dropdown } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // For visibility toggle
import { useNavigate, useParams } from 'react-router-dom';
import { API } from '../api/API';
import { alertWarning } from '../alert';


const ForgotPassUserPage = () => {
  const navigate = useNavigate();
  const param = useParams();
  const api = new API();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  let userid = param.userid;

  useEffect(() => {
    // Fetch user data  with role for editing
    async function getuser_by_id() {
      const response = await api.get_user_roles_by_id(userid);
      const userData = response;
      // setName(userData.name);
      setUsername(userData.username);
    }

    getuser_by_id();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (password === confirmPassword) {
      const data = { username, password };
      const response = await api.forgot_password(data,navigate);
      console.log(response);
    }
    else {
      alertWarning("Password Mismatch!!!");
    }
  };

  const handleBackSubmit = (event) => {
    navigate(-1)
  };

  return (
    <div className='basic' style={{ position: 'relative', marginTop: '40px' }}>
      <Container style={{ width: '27%' }}>
        <h2 style={{ textAlign: 'center', fontWeight: '400' }}>Reset Password</h2> <br />
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formUsername" style={{ marginBottom: "17px" }}>
            <Form.Label>Username</Form.Label>
            <Form.Control
              type="text"
              // placeholder="Enter username"
              value={username}
              // onChange={(e) => setUsername(e.target.value)}
              disabled
            />
          </Form.Group>

          <Form.Group controlId="formPassword" style={{ marginBottom: "17px" }}>
            <Form.Label>New Password</Form.Label>
            <Form.Control
              type={passwordVisible ? 'text' : 'password'}
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoFocus
              required
            />
            <Button
              variant="link"
              onClick={() => setPasswordVisible(!passwordVisible)}
              style={{ position: 'absolute', right: '38%', top: '35%', color: "#243847" }}
            >
              {passwordVisible ? <FaEyeSlash /> : <FaEye />}
            </Button>
          </Form.Group>

          <Form.Group controlId="formConfirmPassword">
            <Form.Label>Confirm New Password</Form.Label>
            <Form.Control
              type={confirmPasswordVisible ? 'text' : 'password'}
              placeholder="Confirm password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <Button
              variant="link"
              onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
              style={{
                position: 'absolute', right: "38%",
                top: "61%", color: "#243847"
              }}
            >
              {confirmPasswordVisible ? <FaEyeSlash /> : <FaEye />}
            </Button>
          </Form.Group>

          <Button
            variant="dark"
            onClick={handleBackSubmit}
            type="button" // Change to button to avoid submitting the form
            className="user-register-back-btn"
            size="sm"
            style={{ backgroundColor: '#243847', borderColor: '#243847', margin: '15px 30px', position: 'relative',
              left: '-10%' }}
          >
            Back
          </Button>
          <Button
            variant="dark"
            type="submit"
            className="user-register-create-btn"
            size="sm"
            style={{ backgroundColor: '#243847', borderColor: '#243847' , position: 'relative',
              left: '13%'}}
          >
            Update
          </Button>
        </Form>
      </Container>
    </div>
  );
};

export default ForgotPassUserPage;
