import React, { useEffect, useState } from 'react'
import { Navigate, Link, useNavigate } from 'react-router-dom'
import { alertSuccess, alertSuccessLogin, alertWarning } from "../alert";
import Logo from "../images/logo2.png";
import '../css/LoginPage.css'
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import LoginIcon from '@mui/icons-material/Login';
import { API } from '../api/API';


function Loginpage() {

  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [token, setToken] = useState(null);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const api = new API();
  const navigate = useNavigate("")

  useEffect(() => {
    const x = localStorage.getItem("userDetails");

    if (x) {
      return navigate('/');
    }
  })

  //   const {loginUser} = useContext(AuthContext)
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = { username, password };

      const response = await api.user_auth_login(data);

      const { message, user, access } = response.data;

      if (message === 'You are logged in somewhere else. Please log out from other devices.') {
        alertWarning(message);
      } else {
        console.log(response.data);

        // Store JWT token, user details, and session key separately in localStorage
        setToken(access);
        localStorage.setItem('token', access); // Store JWT token
        localStorage.setItem("userDetails", JSON.stringify(user)); // Store user details
        navigate("/");

        // alertSuccessLogin(message, navigate, "/");
      }
    } catch (error) {
      if (error.response && error.response.data) {
        alertWarning(error.response.data.message || 'An error occurred');
      } else {
        alertWarning('An error occurred');
      }
    }
  };



  return (
    <div className="container1">
    <div className="left-section"><img src={Logo} alt="" height="55"/>
    <div><h6>Luftek Engineering Private Limited
    </h6>
    <p>Quality and Innovative Air Conditioning Products.</p></div></div>
    <div className="right-section">
      <div className="form-container">
        <form onSubmit={handleSubmit}>
          <div>
            <img src={Logo} alt="Logo" />
          </div>
          <div>
            <label>Username</label>
            <input
              type="text"
              name="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div style={{ position: 'relative' }}>
            <label>Password</label>
            <input
              type={isPasswordVisible ? 'text' : 'password'}
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
            >
              {isPasswordVisible ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          <div>
            <button type="submit">Login <LoginIcon/></button>
          </div>
        </form>
      </div>
    </div>
  </div>
  
  )
}

export default Loginpage