import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { alertSuccess, alertWarning } from '../alert.js';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import * as excel from 'xlsx';
import * as utility from '../utils.js';
import { API } from '../api/API.js';
import axios from 'axios';
import '../css/custom.css';


export default function Upload() {
  const header = 'BOM Upload';
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();
  const [status, setStatus] = useState(true);
  const [submitted, setSubmitted] = useState(false);

  const [transactionData, setTransactionData] = useState({});
  const [data, setData] = useState({
    'sales_order_number': '',
    'production_order_number': '',
    'job_transaction_number': '',
    'client': '',
    'project': '',
    'reference': '',
    'production_quantity': 1,
  });


  const [validated, setValidated] = useState(false);
  const [hasPermission, setHasPermission] = useState(false);

  const [checkHeaders, setCheckHeaders] = useState(true);

  const api = new API();

  const headers = {
    'Item Code': 'item_number',
    'Item Description': 'item_description',
    'Item Level': 'item_level',
    'Item Type': 'item_type',
    'Qty': 'item_qty',
    'Unit': 'uom'
  };

  const requiredHeaders = ['Item Code', 'Item Description', 'Item Level', 'Item Type', 'Qty', 'Unit'];


  const handleChange = (event) => {
    const { name, value } = event.target;
    setData(currData => {
      return {
        ...currData,
        [name]: value
      }
    })
  }

  const handleChange1 = (e) => {
    const { name, value } = e.target;

    // Regular expression to allow only alphanumeric characters (no special characters)
    const regex = /^[a-zA-Z0-9_\-:]*$/;

    if (regex.test(value)) {
      setData((prevState) => ({
        ...prevState,
        [name]: value
      }));
    } else {
      // Optionally, show an error message or handle invalid input
      console.log("Invalid input, special characters are not allowed.");
    }
  };

  const validate = (jsonData) => {
    let status = new Array(jsonData.length).fill('');
    var index = 0;

    const actualHeaders = Object.keys(jsonData[0]);
    const headersMatch = requiredHeaders.every(header => actualHeaders.includes(header)) && actualHeaders.every(header => requiredHeaders.includes(header));

    if (!headersMatch) {
      alertWarning('Please upload a valid BOM file.');
      setCheckHeaders(false);
    }

    jsonData.map((row) => {

      Object.entries(row).forEach(([key, value]) => {
        if (value == '') {
          status[index] = (`The ${key} value is empty.`)
        }
        else if (key == 'Qty' && isNaN(value)) {
          status[index] = (`The ${key} has a non numeric value.`)
        }
        else if (key == 'Unit' && !/^[A-Za-z\s_]+$/.test(value)) {
          status[index] = (`UOM must contain only alphabetic characters.`)
        }

      });
      index++;


    });
    return jsonData.map((row, index) => ({
      ...row,
      Status: status[index] // Add status field to each row
    }));

  };

  const handleFile = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const arrayBuffer = e.target.result;
      const workbook = excel.read(arrayBuffer, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = excel.utils.sheet_to_json(worksheet, { defval: '' });
      console.log(jsonData, 'jsonData');

      const validatedData = validate(jsonData);
      let hasStatus = validatedData.some(item => { return item.Status && item.Status.trim() != '' });
      setStatus(hasStatus);
      if (hasStatus == false) {
        const mappedData = utility.mapHeaders(jsonData, headers);
        console.log(mappedData, 'mappedData');

        setTransactionData(mappedData);
      }
      setTableData(validatedData);
    };

    reader.readAsArrayBuffer(file);
  };

  useEffect(() => {
    if (!checkHeaders) {
      setStatus(true);
    }


  }, [checkHeaders])

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      event.preventDefault();

      // Check if any item in transactionData has 'FG' or 'SFG' in item_type
      const hasFg = transactionData.filter((item) => item.item_type === 'FG')
      const hasSfg = transactionData.filter((item) => item.item_type === 'SFG')
      const hasBoc = transactionData.filter((item) => item.item_type === 'BOC')
      console.log(hasFg, 'hasFg');

      if (hasFg.length == 0) {
        alertWarning('Please upload the BOM with FG part.')
      }
      else if (hasFg.length > 1) {
        alertWarning('One BOM must contain only one FG part.')
      }
      else if (hasSfg.length == 0 && hasBoc.length == 0) {
        alertWarning('At least one SFG or BOC must be included in the BOM.')
      }
      else {
        // Proceed with upload if 'FG' or 'SFG' is found
        api.production_transaction_create(data, transactionData, navigate);
      }
    }
  };


  return (
    <>
      <div className='basic' style={{ position: "relative", marginTop: "20px" }}>
        <Container>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row>
              <Col md={4} style={{ marginTop: "20px" }}>
                <Form.Label>Sales Order Number</Form.Label>
                <Form.Control
                  type="text"
                  name="sales_order_number"
                  className="custom-input1"
                  value={data.sales_order_number}
                  onChange={handleChange1}
                  required
                  autoFocus
                />
                <Form.Control.Feedback type='invalid'>This is a required field.</Form.Control.Feedback>
              </Col>

              <Col md={4} style={{ marginTop: "20px" }}>
                <Form.Label>Production Order Number</Form.Label>
                <Form.Control
                  type="text"
                  name="production_order_number"
                  className="custom-input1"
                  value={data.production_order_number}
                  onChange={handleChange1}

                  required
                />
                <Form.Control.Feedback type='invalid'>This is a required field.</Form.Control.Feedback>
              </Col>

              <Col md={4} style={{ marginTop: "20px" }}>
                <Form.Label>Job Tracking Number</Form.Label>
                <Form.Control
                  type="text"
                  name="job_transaction_number"
                  className="custom-input1"
                  value={data.job_transaction_number}
                  onChange={handleChange1}

                  required
                />
                <Form.Control.Feedback type='invalid'>This is a required field.</Form.Control.Feedback>
              </Col>


              <Col md={4} style={{ marginTop: "20px" }}>
                <Form.Label>Client</Form.Label>
                <Form.Control
                  type="text"
                  name="client"
                  className="custom-input1"
                  value={data.client}
                  onChange={handleChange}

                  required
                />
                <Form.Control.Feedback type='invalid'>This is a required field.</Form.Control.Feedback>
              </Col>


              <Col md={4} style={{ marginTop: "20px" }}>
                <Form.Label>Project</Form.Label>
                <Form.Control
                  type="text"
                  name="project"
                  className="custom-input1"
                  value={data.project}
                  onChange={handleChange}

                  required
                />
                <Form.Control.Feedback type='invalid'>This is a required field.</Form.Control.Feedback>
              </Col>


              <Col md={4} style={{ marginTop: "20px" }}>
                <Form.Label>Reference</Form.Label>
                <Form.Control
                  type="text"
                  name="reference"
                  className="custom-input1"
                  value={data.reference}
                  onChange={handleChange}

                  required
                />
                <Form.Control.Feedback type='invalid'>This is a required field.</Form.Control.Feedback>
              </Col>

            </Row>

            <br />

            <Row>

              <Col md={4}>
                <Form.Label>Production Quantity</Form.Label>
                <Form.Control
                  type="number"
                  name="production_quantity"
                  className="custom-input1"
                  disabled
                  value={data.production_quantity}
                  onChange={handleChange}

                  required
                />
                <Form.Control.Feedback type='invalid'>This is a required field.</Form.Control.Feedback>
              </Col>

              <Col md={{ span: 6, offset: 1 }} >
                <Form.Label>Upload File</Form.Label>
                <Form.Group
                  controlId="formFile"
                  className=" d-flex align-items-center"
                >
                  {/* <UploadIcon className="mr-2 w-20" /> */}
                  <Form.Control type="file" onChange={handleFile} />
                </Form.Group>
              </Col>
            </Row>

            
            <br />
            <a
            component="a"
            href="/BOM/BOM Upload File Template.xlsx" // Replace with the actual file URL
            download
            style={{color: '#243847', border: 'none', fontSize: '15px', position: 'relative', top: '70px'}}
            >
            Download Template <SaveAltIcon style={{fontSize: '18px'}}/>
            </a>
            {/* <Button
              href="/android-apk/Android APK.apk" // Replace with the actual file URL
              download
              className='custom-button'
              sx={{
                textDecoration: 'none',
                color: 'white',
                backgroundColor: '#243847', // Background color
                textTransform: 'none',
                padding: '8px 16px', // Adjust padding as needed for button size
                '&:hover': {
                  backgroundColor: '#1e2a30', // Darker shade for hover effect
                },
              }}
            >
              Download <SaveAltIcon/>
            </Button> */}
            <div className='right-align'>
              <Button variant='dark' disabled={status} type='submit' className='custom-button' size='sm'>Upload</Button>
            </div>

            <br />
            <div className="bom-table">
              <table >
                <thead>
                  <tr>
                    <th style={{ width: '120px' }}>Item Level</th>
                    <th style={{ width: '130px' }}>Item Type</th>
                    <th>Item Code</th>
                    <th>Item Description</th>
                    <th>Quantity</th>
                    <th>UOM</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.length > 0 ? (
                    tableData.map((row, index) => (
                      <tr key={index}>
                        {Object.values(row).map((value, idx) => (
                          <td key={idx}>{value}</td>
                        ))}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan='7' style={{ textAlign: 'center' }}>
                        No data available
                      </td>
                    </tr>
                  )}

                </tbody>
              </table>
            </div>
          </Form>

        </Container>
      </div>
    </>
  );
}
