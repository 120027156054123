// Tab.js
import React from 'react';
// import './Tab.css'; // Import the CSS for the tab styling

const Tab = ({ children, className, onClick }) => {
  return (
    <div className={`tab ${className}`} onClick={onClick}>
      {children}
    </div>
  );
};

export default Tab;
