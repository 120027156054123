import { useState, useEffect } from "react";
import { API } from "../api/API";
import { useLocation, useNavigate} from "react-router-dom";
import Container from "react-bootstrap/esm/Container";
import Button from "react-bootstrap/esm/Button";
import DataTable from "react-data-table-component";

export default function PackNumberReport() {
  const api = new API();
  const [data, setData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const pack_number = location.state.pack_number;
  const sales_order_number = location.state.sales_order_number;
  const production_order_number = location.state.production_order_number;
  const job_transaction_number = location.state.job_transaction_number;


  useEffect(() => {
    async function get_pack_number() {
      const response = await api.reports_get_pack_number(pack_number);
      setData(response);
    }

    get_pack_number();
  }, [pack_number]);
    // State for search terms
  const [filters, setFilters] = useState({
    packNumber: '',
    serialNumber: '',
    itemDescription: '',
    created_at: '',
    created_by: ''
  });

  // Handle filter input changes
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));
  };

  // Filter data based on filters
  const filteredData = data.filter(row =>
    row.pack_number.toLowerCase().includes(filters.packNumber.toLowerCase()) &&
    row.serial_number.toString().toLowerCase().includes(filters.serialNumber.toString().toLowerCase()) &&      
    row.created_at.toString().toLowerCase().includes(filters.created_at.toString().toLowerCase()) &&  
    row.created_by.toString().toLowerCase().includes(filters.created_by.toString().toLowerCase()) &&
    row.item_description.toString().toLowerCase().includes(filters.itemDescription.toString().toLowerCase())  
  );


  

  const columns = [
    {
      name: (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
            <span>S.No.</span>
            <input
              type="text"
              name="assemblyStatus"
              // value={filters.assemblyStatus}
              // onChange={handleFilterChange}
              placeholder="S.No."
              style={{ marginTop: '3px', width: '100%', height: "25px", padding: '5px' }}
            />
          </div>
        </>
      ),
      selector: (row, index) => filteredData.length ? index + 1 : '',
      sortable: false,
      style: {
        textAlign: "center",
      },
      width: "70px"
    },

    {
      name: (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
            <span>Pack Number</span>
            <input
              type="text"
              name="packNumber"
              value={filters.packNumber}
              onChange={handleFilterChange}
              placeholder="Pack Number"
              style={{ marginTop: '3px', width: '100%', height: "25px", padding: '5px' }}
            />
          </div>
        </>
      ),
      selector: (row) => row.pack_number,
      sortable: false,
      style: {
        textAlign: "center",
      },
    },

    {
        name: (
          <>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
              <span>Serial Number</span>
              <input
                type="text"
                name="serialNumber"
                value={filters.serialNumber}
                onChange={handleFilterChange}
                placeholder="Serial Number"
                style={{ marginTop: '3px', marginBottom:'2px',width: '100%', height: "25px", padding: '5px' }}
              />
            </div>
          </>
        ),
        selector: (row) => row.serial_number,
        sortable: false,
        style: {
          textAlign: "center",
        },
    },

    {
      name: (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
            <span>Part Description</span>
            <input
              type="text"
              name="itemDescription"
              value={filters.itemDescription}
              onChange={handleFilterChange}
              placeholder="Part Description"
              style={{ marginTop: '3px', marginBottom:'2px',width: '100%', height: "25px", padding: '5px' }}
            />
          </div>
        </>
      ),
      selector: (row) => row.item_description,
      sortable: false,
      style: {
        textAlign: "center",
      },
    },

    {
      name: (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
            <span>Created By</span>
            <input
              type="text"
              name="created_by"
              value={filters.created_by}
              onChange={handleFilterChange}
              placeholder="Created By"
              style={{ marginTop: '3px', marginBottom:'2px',width: '100%', height: "25px", padding: '5px' }}
            />
          </div>
        </>
      ),
      selector: (row) => row.created_by,
      sortable: false,
      style: {
        textAlign: "center",
      },
    },
    {
      name: (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
            <span>Created At</span>
            <input
              type="text"
              name="created_at"
              value={filters.created_at}
              onChange={handleFilterChange}
              placeholder="Created At"
              style={{ marginTop: '3px', marginBottom:'2px',width: '100%', height: "25px", padding: '5px' }}
            />
          </div>
        </>
      ),
      selector: (row) => row.created_at,
      sortable: false,
      style: {
        textAlign: "center",
      },
    },
  ];

  const handleBackSubmit = () => {
    navigate(-1);
  };

  return (
    <>
      <div
        className="basic"
        style={{ position: "relative", marginTop: "30px" }}
      >
        {" "}
      </div>

      <Container>

      <span style={{ paddingRight: "30px" }}>
          <b> SO #: {sales_order_number} </b>
        </span>

      <span style={{ paddingRight: "30px" }}>
        <b> PO #: {production_order_number} </b>
      </span>
      <span style={{ paddingRight: "30px" }}>
        <b> JTN #: {job_transaction_number} </b>
      </span>

      <div style={{marginBottom: '20px'}} className=""></div>


        <DataTable
          className="data-table-container"
          columns={columns}
          data={filteredData.length ? filteredData : [{}]}
          pagination
          responsive
          highlightOnHover
          dense
        />

        <Button
          variant="dark"
          onClick={handleBackSubmit}
          type="button" // Change to button to avoid submitting the form
          className="user-register-back-btn"
          size="sm"
          style={{
            backgroundColor: "#243847",
            borderColor: "#243847",
            position: "relative",
            top: "0px",
          }}
        >
          Back
        </Button>
      </Container>
    </>
  );
}
