import React, { useEffect, useState } from 'react';
import { Form, Button, Container, Row, Col, Dropdown } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // For visibility toggle
import { useNavigate } from 'react-router-dom';
import { API } from '../api/API';
import { alertWarning } from '../alert';


const UserRegister = () => {
  const navigate = useNavigate();
  const api = new API();
  //userCredentials
  const [roles, setRoles] = useState([])
  
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  // const selectedRoleName = roles.find(role => role.id === selectedRole)?.name || "Select a role";
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);


  useEffect(() => {
    async function get() {
      const response = await api.roles_get_all_role();
      const userRole = response.find(role => role.name === 'User');

      console.log(userRole);

      setRoles(userRole)
    }
    get();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (password === confirmPassword) {
      const data = { name, email, username, password, role: roles.id };
      const response = await api.register_user(data, navigate);
      console.log(response);
      // navigate('/')
    }
    else {
      alertWarning("Password Mismatch!!!");

    }
  };

  const handleBackSubmit = (event) => {
    navigate("/users")
  };

  return (
    <div className='basic' style={{ position: 'relative', marginTop: '40px' }}>
      <Container style={{ width: '55%' }}>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  autoFocus
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formUsername">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formRole">
                <Form.Label>Role</Form.Label>
                <Form.Control
                  type="text"
                  value={roles.name}
                  disabled
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type={passwordVisible ? 'text' : 'password'}
                  placeholder="Enter password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <Button
                  variant="link"
                  onClick={() => setPasswordVisible(!passwordVisible)}
                  style={{ position: 'absolute', right: '52%', top: '42%', color: "#243847" }}
                >
                  {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                </Button>
              </Form.Group>
            </Col>
            {/* </Row>
          <Row> */}
            <Col md={6}>
              <Form.Group controlId="formConfirmPassword">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type={confirmPasswordVisible ? 'text' : 'password'}
                  placeholder="Confirm password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
                <Button
                  variant="link"
                  onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
                  style={{
                    position: 'absolute', right: "24%",
                    top: "42%", color: "#243847"
                  }}
                >
                  {confirmPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                </Button>
              </Form.Group>
            </Col>
          </Row>
          <Form.Text className="text-muted" style={{ fontSize: '0.8rem' }}>
                  Password must be at least 8 characters long and include:
                  <ul style={{ margin: 0, paddingLeft: '1.2rem' }}>
                    <li>At least 1 uppercase letter (A-Z)</li>
                    <li>At least 1 lowercase letter (a-z)</li>
                    <li>At least 1 number (0-9)</li>
                    <li>At least 1 special character (e.g., @, #, $, %)</li>
                  </ul>
                </Form.Text>
          <Button
            variant="dark"
            onClick={handleBackSubmit}
            type="button" // Change to button to avoid submitting the form
            className="user-register-back-btn"
            size="sm"
            style={{ backgroundColor: '#243847', borderColor: '#243847', margin: '15px 30px', position: 'relative',
              left: '-5%' }}
          >
            Back
          </Button>
          <Button
            variant="dark"
            type="submit"
            className="user-register-create-btn"
            size="sm"
            style={{ backgroundColor: '#243847', borderColor: '#243847',position: 'relative',
              left: '59%' }}
          >
            Create
          </Button>
        </Form>
      </Container>
    </div>
  );
};

export default UserRegister;
