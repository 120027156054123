import './App.css'
import { Route, RouterProvider, Routes, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import './css/custom.css';
import LoginPage from './login-page/LoginPage';
import ProtectPage from './login-page/ProtectPage';
import RoutesPage from './login-page/RoutesPage';
import IdleTimeOut from './login-page/IdleTimeOut';

function App() {

  const routes = createRoutesFromElements(
    <Route>
      {/* Apply IdleTimeOut and ProtectPage to RoutesPage */}
      <Route 
        path="/*" 
        element={
          <ProtectPage Child={() => (
            <IdleTimeOut>
              <RoutesPage />
            </IdleTimeOut>
          )} 
        />} 
      />
      {/* Login route */}
      <Route path="/login" element={<LoginPage />} />
    </Route>
  )

  const router = createBrowserRouter(routes);
  
  return (

    <div>
      <RouterProvider router={router} />

    </div>
  );
}

export default App;
