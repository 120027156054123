import { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { API } from "../api/API";
import Container from "react-bootstrap/esm/Container";
import { useNavigate, useLocation } from "react-router-dom";
import Tabs from "react-bootstrap/esm/Tabs";
import Tab from "react-bootstrap/esm/Tab";
import Button from "react-bootstrap/esm/Button";
import { NavLink } from "react-router-dom";
import { width } from "@fortawesome/free-solid-svg-icons/fa0";

export default function DetailedReport() {
  const api = new API();
  const location = useLocation();
  const navigate = useNavigate();
  const job_transaction_id = location.state.jobTransactionID;
  const sales_order_number = location.state.salesOrderNumber;
  const production_order_number = location.state.productionOrderNumber;
  const job_transaction_number = location.state.jobTransactionNumber;
  let print_data = {}

  var currentDate = new Date();

  console.log(currentDate, 'currentDate');


  const [key, setKey] = useState("FG/SFG");

  const [data, setData] = useState({
    fg_sfg_data: [],
    boc_data: [],
    total_boc_count: "",
    pickslip_number: "",
    client_reference_project: [],
  });

  // State for search terms
  const [filtersfgsfg, setFiltersfgsfg] = useState({
    sno: "",
    partNumber: "",
    partDescription: "",
    uom: "",
    bomQuantity: "",
    printStatus: "",
    assemblyStatus: "",
    fgStatus: "",
    loadStatus: "",
    created_at: "",
    created_by: "",
  });

  // Handle filter input changes
  const handleFilterfgsfgChange = (e) => {
    const { name, value } = e.target;
    setFiltersfgsfg((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  // Filter data based on filters fg sfg
  const filteredFgSfgData = data.fg_sfg_data.filter(
    (row) =>
      row.item_number
        .toLowerCase()
        .includes(filtersfgsfg.partNumber.toLowerCase()) &&
      row.item_description
        .toLowerCase()
        .includes(filtersfgsfg.partDescription.toLowerCase()) &&
      row.uom.toString().includes(filtersfgsfg.uom) &&
      row.item_qty.toString().includes(filtersfgsfg.bomQuantity) &&
      (row.print_status === "no" || row.print_status === "partial"
        ? "Pending"
        : "Completed"
      )
        .toLowerCase()
        .includes(filtersfgsfg.printStatus.toLowerCase()) &&
      (row.inward_status === "no" || row.inward_status === "partial"
        ? "Pending"
        : "Completed"
      )
        .toLowerCase()
        .includes(filtersfgsfg.assemblyStatus.toLowerCase()) &&
      (row.repack_status === "no" || row.repack_status === "partial"
        ? "Pending"
        : "Completed"
      )
        .toLowerCase()
        .includes(filtersfgsfg.fgStatus.toLowerCase()) &&
      (row.load_status === "no" || row.load_status === "partial"
        ? "Pending"
        : "Completed"
      )
        .toLowerCase()
        .includes(filtersfgsfg.loadStatus.toLowerCase()) &&
      row.created_at.toString().includes(filtersfgsfg.created_at) &&
      row.created_by.toString().includes(filtersfgsfg.created_by)
  );

  // State for search boc terms
  const [filtersboc, setFiltersboc] = useState({
    boxNumber: "",
    loadingSlipNumber: "",
    loadStatus: "",
    created_at: "",
    created_by: "",
  });

  // Handle filter input changes
  const handleFilterbocChange = (e) => {
    const { name, value } = e.target;
    setFiltersboc((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  // Filter data based on filters fg sfg
  const filteredBocData = data.boc_data.filter(
    (row) =>
      row.box_number
        .toLowerCase()
        .includes(filtersboc.boxNumber.toLowerCase()) &&
      row.loading_slip_number
        .toLowerCase()
        .includes(filtersboc.loadingSlipNumber.toLowerCase()) &&
      (row.load_status === "no" || row.load_status === "partial"
        ? "Pending"
        : "Completed"
      )
        .toLowerCase()
        .includes(filtersboc.loadStatus.toLowerCase()) &&
      row.created_at.toString().includes(filtersboc.created_at) &&
      row.created_by.toString().includes(filtersboc.created_by)
  );

  const handlePrint = (tag) => {
    window.open(`/report-print?job_transaction_id=${job_transaction_id}&tag=${tag}&sales_order_number=${sales_order_number}&production_order_number=${production_order_number}&job_transaction_number=${job_transaction_number}`, '_blank');
    window.location.reload();

  };

  useEffect(() => {
    async function get_job_transaction_number() {
      const response = await api.reports_get_job_transaction_number(
        job_transaction_id
      );
      setData(response);
    }

    get_job_transaction_number();
  }, []);

  const fg_sfg_columns = [
    {
      name: (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span>S.No.</span>
            <input
              type="text"
              name="sno"
              value={filtersfgsfg.sno}
              // onChange={handleFilterfgsfgChange}
              placeholder="S.No."
              style={{
                marginTop: "5px",
                marginBottom: "2px",
                width: "100%",
                height: "20px",
                textAlign: "center",
              }}
            />
          </div>
        </>
      ),
      selector: (row, index) => (filteredFgSfgData.length ? index + 1 : ""),
      sortable: false,
      style: {
        textAlign: "center",
      },
      width: "70px",
    },

    {
      name: (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span>Part Number</span>
            <input
              type="text"
              name="partNumber"
              value={filtersfgsfg.partNumber}
              onChange={handleFilterfgsfgChange}
              placeholder="Part Number"
              style={{
                marginTop: "5px",
                width: "100%",
                height: "20px",
                textAlign: "center",
              }}
            />
          </div>
        </>
      ),
      cell: (row) => {

        return row.print_status === "yes" ? (

          <NavLink
            to={{ pathname: "/part-number-report" }}
            state={{
              part_number_id: row.id,
              sales_order_number: sales_order_number,
              production_order_number: production_order_number,
              job_transaction_number: job_transaction_number,
              part_number: row.item_number,
              part_description: row.item_description,
            }}

          >
            <div title={row.item_number} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {row.item_number}
            </div>
          </NavLink>
        ) : (
          row.item_number
        );
      },
      sortable: false,
      style: {
        textAlign: "center",
      },
      width: "140px",
    },

    {
      name: (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span>Part Description</span>
            <input
              type="text"
              name="partDescription"
              value={filtersfgsfg.partDescription}
              onChange={handleFilterfgsfgChange}
              placeholder="Part Description"
              style={{
                marginTop: "5px",
                width: "100%",
                height: "20px",
                textAlign: "center",
              }}
            />
          </div>
        </>
      ),
      selector: (row) => row.item_description,
      sortable: false,
      style: {
        textAlign: "center",
      },
      width: "170px",
      cell: row => (
        <div title={row.item_description} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {row.item_description}
        </div>
      ),
    },

    {
      name: (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span>UOM</span>
            <input
              type="text"
              name="uom"
              value={filtersfgsfg.uom}
              onChange={handleFilterfgsfgChange}
              placeholder="UOM"
              style={{
                marginTop: "5px",
                width: "100%",
                height: "20px",
                textAlign: "center",
              }}
            />
          </div>
        </>
      ),
      selector: (row) => row.uom,
      sortable: false,
      style: {
        textAlign: "center",
      },
    },

    {
      name: (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span>BOM Quantity</span>
            <input
              type="text"
              name="bomQuantity"
              value={filtersfgsfg.bomQuantity}
              onChange={handleFilterfgsfgChange}
              placeholder="BOM Quantity"
              style={{
                marginTop: "5px",
                width: "100%",
                height: "20px",
                textAlign: "center",
              }}
            />
          </div>
        </>
      ),
      selector: (row) => row.item_qty,
      sortable: false,
      style: {
        textAlign: "center",
      },
      width: "140px",
    },

    {
      name: (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span>Print Status</span>
            <input
              type="text"
              name="printStatus"
              value={filtersfgsfg.printStatus}
              onChange={handleFilterfgsfgChange}
              placeholder="Print Status"
              style={{
                marginTop: "5px",
                width: "100%",
                height: "20px",
                textAlign: "center",
              }}
            />
          </div>
        </>
      ),
      selector: (row) =>
        filteredFgSfgData.length
          ? row.print_status == "yes"
            ? "Completed"
            : "Pending"
          : "",
      sortable: false,
      style: {
        textAlign: "center",
      },
      width: "135px",
    },

    {
      name: (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span>Production Status</span>
            <input
              type="text"
              name="assemblyStatus"
              value={filtersfgsfg.assemblyStatus}
              onChange={handleFilterfgsfgChange}
              placeholder="Production Status"
              style={{
                marginTop: "5px",
                width: "100%",
                height: "20px",
                textAlign: "center",
              }}
            />
          </div>
        </>
      ),
      selector: (row) =>
        filteredFgSfgData.length
          ? row.inward_status == "yes"
            ? "Completed"
            : "Pending"
          : "",
      sortable: false,
      style: {
        textAlign: "center",
      },
      width: "165px",
    },

    {
      name: (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span>Assembly Status</span>
            <input
              type="text"
              name="fgStatus"
              value={filtersfgsfg.fgStatus}
              onChange={handleFilterfgsfgChange}
              placeholder="Assembly Status"
              style={{
                marginTop: "5px",
                width: "100%",
                height: "20px",
                textAlign: "center",
              }}
            />
          </div>
        </>
      ),
      selector: (row) =>
        filteredFgSfgData.length
          ? row.repack_status == "no"
            ? "Pending"
            : row.repack_status
              .map((pack_number, index) => (
                <NavLink
                  to={{ pathname: "/pack-number-report" }}
                  state={{
                    pack_number: pack_number,
                    sales_order_number: sales_order_number,
                    production_order_number: production_order_number,
                    job_transaction_number: job_transaction_number,
                  }}
                >
                  {pack_number}
                </NavLink>
              ))
              .reduce((prev, curr) => [prev, ", ", curr])
          : "",
      sortable: false,
      style: {
        textAlign: "center",
      },
      width: "350px",
    },

    {
      name: (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span>Load Status</span>
            <input
              type="text"
              name="loadStatus"
              value={filtersfgsfg.loadStatus}
              onChange={handleFilterfgsfgChange}
              placeholder="Load Status"
              style={{
                marginTop: "5px",
                width: "100%",
                height: "20px",
                textAlign: "center",
              }}
            />
          </div>
        </>
      ),
      selector: (row) =>
        filteredFgSfgData.length
          ? row.load_status == "no"
            ? "Pending"
            : row.load_status
              .map((loading_slip_number, index) => (
                <NavLink
                  to={{ pathname: "/loadingslip-number-report" }}
                  state={{
                    loading_slip_number: loading_slip_number,
                    sales_order_number: sales_order_number,
                  }}
                >
                  {loading_slip_number}
                </NavLink>
              ))
              .reduce((prev, curr) => [prev, ", ", curr])
          : "",
      sortable: false,
      style: {
        textAlign: "center",
      },
      width: "350px",
    },

    {
      name: (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span>Created By</span>
            <input
              type="text"
              name="created_by"
              value={filtersfgsfg.created_by}
              onChange={handleFilterfgsfgChange}
              placeholder="Created By"
              style={{
                marginTop: "5px",
                width: "100%",
                height: "20px",
                textAlign: "center",
              }}
            />
          </div>
        </>
      ),
      selector: (row) => row.created_by,
      sortable: false,
      style: {
        textAlign: "center",
      },
      width: "135px",
    },
    {
      name: (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span>Created at</span>
            <input
              type="text"
              name="created_at"
              value={filtersfgsfg.created_at}
              onChange={handleFilterfgsfgChange}
              placeholder="Created at"
              style={{
                marginTop: "5px",
                width: "100%",
                height: "20px",
                textAlign: "center",
              }}
            />
          </div>
        </>
      ),
      selector: (row) => row.created_at,
      sortable: false,
      style: {
        textAlign: "center",
      },
      width: "165px",
    },
  ];

  const boc_columns = [
    {
      name: (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span>S.No.</span>
            <input
              type="text"
              name="sno"
              value={filtersboc.sno}
              // onChange={handleFilterfgsfgChange}
              placeholder="S.No."
              style={{
                marginTop: "5px",
                width: "100%",
                height: "20px",
                textAlign: "center",
              }}
            />
          </div>
        </>
      ),
      selector: (row, index) => (filteredBocData.length ? index + 1 : ""),
      sortable: false,
      style: {
        textAlign: "center",
      },
      width: "70px",
    },

    {
      name: (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span>Box Number</span>
            <input
              type="text"
              name="boxNumber"
              value={filtersboc.boxNumber}
              onChange={handleFilterbocChange}
              placeholder="Box Number"
              style={{
                marginTop: "5px",
                width: "100%",
                height: "20px",
                textAlign: "center",
              }}
            />
          </div>
        </>
      ),
      selector: (row) => row.box_number,
      sortable: false,
      style: {
        textAlign: "center",
      },
    },

    {
      name: (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span>Loading Slip Number</span>
            <input
              type="text"
              name="loadingSlipNumber"
              value={filtersboc.loadingSlipNumber}
              onChange={handleFilterbocChange}
              placeholder="Loading Slip Number"
              style={{
                marginTop: "5px",
                width: "100%",
                height: "20px",
                textAlign: "center",
              }}
            />
          </div>
        </>
      ),
      cell: (row) => {
        return row.loading_slip_number === "Not yet loaded." ? (
          row.loading_slip_number
        ) : (
          <NavLink
            to={{ pathname: "/loadingslip-number-report" }}
            state={{
              loading_slip_number: row.loading_slip_number,
              sales_order_number: sales_order_number,
            }}
          >
            {row.loading_slip_number}
          </NavLink>
        );
      },
      sortable: false,
      style: {
        textAlign: "center",
      },
    },

    {
      name: (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span>Load Status</span>
            <input
              type="text"
              name="loadStatus"
              value={filtersboc.loadStatus}
              onChange={handleFilterbocChange}
              placeholder="Load Status"
              style={{
                marginTop: "5px",
                width: "100%",
                height: "20px",
                textAlign: "center",
              }}
            />
          </div>
        </>
      ),
      selector: (row) =>
        filteredBocData.length
          ? row.load_status == "yes"
            ? "Completed"
            : "Pending"
          : "",
      sortable: false,
      style: {
        textAlign: "center",
      },
    },

    {
      name: (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span>Created By</span>
            <input
              type="text"
              name="created_by"
              value={filtersboc.created_by}
              onChange={handleFilterbocChange}
              placeholder="Created By"
              style={{
                marginTop: "5px",
                width: "100%",
                height: "20px",
                textAlign: "center",
              }}
            />
          </div>
        </>
      ),
      selector: (row) => row.created_by,
      sortable: false,
      style: {
        textAlign: "center",
      },
    },
    {
      name: (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span>Created at</span>
            <input
              type="text"
              name="created_at"
              value={filtersboc.created_at}
              onChange={handleFilterbocChange}
              placeholder="Created at"
              style={{
                marginTop: "5px",
                width: "100%",
                height: "20px",
                textAlign: "center",
              }}
            />
          </div>
        </>
      ),
      selector: (row) => row.created_at,
      sortable: false,
      style: {
        textAlign: "center",
      },
    },
  ];

  const handleBackSubmit = () => {
    navigate("/reports");
  };

  return (
    <>
      <div
        className="basic"
        style={{ position: "relative", marginTop: "10px" }}
      >
        {" "}
      </div>

      <Container>
        <br />
        <span style={{ paddingRight: "30px" }}>
          <b> SO #: {sales_order_number} </b>
        </span>

        <span style={{ paddingRight: "30px" }}>
          <b> PO #: {production_order_number} </b>
        </span>
        <span style={{ paddingRight: "30px" }}>
          <b> JTN #: {job_transaction_number} </b>
        </span>
        {data.client_reference_project[0] && (
          <>
            <span style={{ paddingRight: "30px" }}>
              <b> Client: {data.client_reference_project[0][0]} </b>
            </span>

            <span style={{ paddingRight: "30px" }}>
              <b> Reference: {data.client_reference_project[0][1]} </b>
            </span>

            <span style={{ paddingRight: "30px" }}>
              <b> Project: {data.client_reference_project[0][2]} </b>
            </span>

            <div style={{ marginBottom: "20px" }} className=""></div>
          </>
        )}

        <Tabs
          defaultActiveKey="profile"
          id="uncontrolled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab style={{ overflowX: "auto" }} eventKey="FG/SFG" title="FG/SFG">
            <DataTable
              className="data-table-container"
              columns={fg_sfg_columns}
              data={filteredFgSfgData.length ? filteredFgSfgData : [{}]}
              pagination
              responsive
              highlightOnHover
              dense
            />




          </Tab>

          <Tab eventKey="BOC" title="BOC">
            <DataTable
              className="data-table-container"
              columns={boc_columns}
              data={filteredBocData.length ? filteredBocData : [{}]}
              pagination
              responsive
              highlightOnHover
              dense
            />
          </Tab>
        </Tabs>

        <Button
          variant="dark"
          onClick={handleBackSubmit}
          type="button" // Change to button to avoid submitting the form
          className="user-register-back-btn"
          size="sm"
          style={{
            backgroundColor: "#243847",
            borderColor: "#243847",
            position: "relative",
            top: "0px",
          }}
        >
          Back
        </Button>
        <Button
          style={{ backgroundColor: '#243847', borderColor: '#243847', position: "relative", left: "38%", padding: "2px 5px" }}
          onClick={() => handlePrint('PRODUCTION')}
          variant="dark"
          type="submit"
          size="sm"
        >
          Print Production Status Report
        </Button>
        <Button
          style={{ backgroundColor: '#243847', borderColor: '#243847', position: "relative", left: "40%", padding: "2px 5px" }}
          onClick={() => handlePrint('ASSEMBLY')}
          variant="dark"
          type="submit"
          size="sm"
        >
          Print Assembly Status Report
        </Button>


        <Button
          style={{ backgroundColor: '#243847', borderColor: '#243847', position: "relative", left: "42%", padding: "2px 5px" }}
          onClick={() => handlePrint('LOAD')}
          variant="dark"
          type="submit"
          size="sm"
        >
          Print Load Status Report
        </Button>

      </Container>
    </>
  );
}
