import axios from "axios";
import { BaseURL, getToken } from "../utils";

import { alertSuccess, alertWarning, alertWarningBig } from "../alert";
class API {

    // Authentication and Authorisation API

    async user_auth_login(data) {
        const response = await axios.post(`${BaseURL}/login/`, data);
        console.log(response.data);

        return response;
    }

    async user_auth_logout(userId) {
        const response = await axios.post(
            `${BaseURL}/logout/`,
            { userId },
            {
                //   headers: {
                //     Authorization: `Bearer ${getToken()}`,
                //   },
            }
        );
        return response;
    }

    async register_user(data, navigate) {
        try {
            const response = await axios.post(`${BaseURL}/register/`, data)
            alertSuccess("User Saved Successfully!!!", navigate, '/users')
            return response.data
        }
        catch (error) {
            alertWarning(error.response.data.error);
        }
    }

    async update_user(data, userid, navigate) {
        try {
            const response = await axios.put(`${BaseURL}/update-user/${userid}/`, data
                , {
                    headers: {
                        Authorization: `Bearer ${getToken()}`,
                    },
                }
            )
            alertSuccess("User Updated Successfully!!!", navigate, '/users')
            return response.data
        }
        catch (error) {
            if (error.response && error.response.data && error.response.data.error) {
                alertWarning(error.response.data.error);
            } else {
                alertWarning('Please try again.');
            }
        }
    }

    async forgot_password(data, navigate) {
        try {
            const response = await axios.post(`${BaseURL}/forgot-password/`, data)
            alertSuccess("Password Updated Successfully", navigate, '/')
            return response.data
        }
        catch (error) {
            alertWarning(error.response.data.error);
        }
    }

    async roles_get_all_role() {
        try {
            const response = await axios.get(`${BaseURL}/get-roles/`
                , {
                    headers: {
                        Authorization: `Bearer ${getToken()}`,
                    },
                }
            );
            return response.data;
        }
        catch (error) {
            alertWarning('Please try again.');
        }

    }

    async assign_role_permissions(data, navigate) {
        try {
            const response = await axios.post(`${BaseURL}/assign_role_permissions/`, data, {
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                },
            });
            alertSuccess(response.data.message, navigate, '/roles');
            return response;
        } catch (error) {
            if (error.response && error.response.data) {
                console.error('Error from backend:', error.response.data);
                alertWarning(`${error.response.data.error || error.response.data.message}`);
            } else {
                console.error('Error saving permissions:', error);
                alertWarning('Failed to save permissions.');
            }
        }
    }

    async update_role_permissions(data, navigate) {
        try {
            const response = await axios.put(`${BaseURL}/update-role-permissions/`, data
                , {
                    headers: {
                        Authorization: `Bearer ${getToken()}`,
                    },
                }
            );
            alertSuccess(response.data.message, navigate, '/roles');
            return response;
        }
        catch (error) {
            console.error('Error saving permissions:', error);
            alertWarning('Failed to save permissions.');
        }
    }

    async get_role_permissions(role) {
        try {
            const response = await axios.get(`${BaseURL}/get-role-permissions/${role}/`
                , {
                    headers: {
                        Authorization: `Bearer ${getToken()}`,
                    },
                }
            )
            return response.data
        }
        catch (error) {
            console.error("Couldn't fetch data!!!");

        }
    }

    async get_user_roles_by_id(userid) {
        try {
            const response = await axios.get(`${BaseURL}/get-user-roles-by-id/${userid}/`
                , {
                    headers: {
                        Authorization: `Bearer ${getToken()}`,
                    },
                }
            )
            // console.log(response.data,"data");
            return response.data

        }
        catch (error) {
            console.error("Couldn't fetch data!!!");
        }
    }

    async get_users_with_roles() {
        try {
            const response = await axios.get(`${BaseURL}/get-users-with-roles/`
                , {
                    headers: {
                        Authorization: `Bearer ${getToken()}`,
                    },
                }
            )
            return response.data;
        }
        catch (error) {
            console.error('Error:', error);
        }
    }


    // Production Order API

    async production_header_get() {
        try {
            const response = await axios.get(`${BaseURL}/production-header-get`
                , {
                    headers: {
                        Authorization: `Bearer ${getToken()}`,
                    },
                }
            );
            return response.data;
        }
        catch (error) {
            alertWarning(JSON.stringify(error.response.data));
        }
    }

    async production_header_delete(production_order_header_id, navigate) {

        try {
            const response = await axios.delete(`${BaseURL}/production-header-delete`, {
                params: { production_order_header_id },
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                },
            }
            );
            if (response.data == 'success') {
                const successMessage = 'BOM details deleted successfully.';
                const navigationComponent = '/bom-inactive';
                alertSuccess(successMessage, navigate, navigationComponent);

            }
        }
        catch (error) {
            alertWarning(JSON.stringify(error.response.data));
        }

    }

    async production_header_inactive(production_order_header_id, navigate) {
        try {
            const response = await axios.patch(`${BaseURL}/production-header-inactive`, {
                production_order_header_id
            },
                {
                    headers: {
                        Authorization: `Bearer ${getToken()}`,
                    },
                }
            );
            if (response.data == 'success') {
                const successMessage = 'BOM details inactivated successfully.';
                const navigationComponent = '/bom-inactive';
                alertSuccess(successMessage, navigate, navigationComponent);

            }
        }
        catch (error) {
            alertWarning(JSON.stringify(error.response.data));
        }
    }

    async production_transaction_get(production_order_id) {
        try {
            const response = await axios.get(`${BaseURL}/production-transaction-get`, {
                params: { production_order_id }, headers: {
                    Authorization: `Bearer ${getToken()}`,
                },
            });
            return response.data;
        }
        catch (error) {
            alertWarning(JSON.stringify(error.response.data));
        }

    }

    async production_transaction_create(data, transactionData, navigate) {
        const payload = {
            data, transactionData
        };
        try {
            const response = await axios.post(`${BaseURL}/production-transaction-create`, payload
                , {
                    headers: {
                        Authorization: `Bearer ${getToken()}`,
                    },
                }
            );
            if (response.data == 'Success') {
                const successMessage = 'BOM details uploaded successfully.';
                const navigationComponent = '/label-print-index';
                alertSuccess(successMessage, navigate, navigationComponent);
            }
            else {

                alertWarningBig(response.data);
            }

        }
        catch (error) {
            alertWarning('Please try again.');
        }

    }


    // Label Print API

    async label_print_transaction_create(data, production_order_number, production_order_qty, job_transaction_number, selected_label, navigate) {
        try {
            const payload = {
                data, selected_label, production_order_number, production_order_qty, job_transaction_number
            };
            const response = await axios.post(`${BaseURL}/print-sfg-fg`, payload
                , {
                    headers: {
                        Authorization: `Bearer ${getToken()}`,
                    },
                }
            );
            const printData = JSON.stringify(response.data);
            localStorage.removeItem('printData');
            localStorage.removeItem('labelReprintData');
            localStorage.setItem('printData', printData);


            if (selected_label === "FG/SFG Template 1") {
                window.open(`/fg-sfg-print?`, '_blank');
                window.focus();
                window.location.reload();
            }
            else if (selected_label === "FG/SFG Template 2") {
                window.open(`/fg-sfg-print1?`, '_blank');
                window.focus();
                window.location.reload();
            }



        }
        catch (error) {
            alertWarning('Please try again.');
        }

    }

    async label_reprint_transaction_create(data, production_order_number, production_order_qty, job_transaction_number, reason, selected_label, navigate) {
        try {
            const payload = {
                data, selected_label, production_order_number, production_order_qty, job_transaction_number, reason
            };
            const response = await axios.post(`${BaseURL}/reprint-sfg-fg`, payload
                , {
                    headers: {
                        Authorization: `Bearer ${getToken()}`,
                    },
                }
            );

            const printData = response.data;
            localStorage.removeItem('printData');
            localStorage.removeItem('labelReprintData');
            localStorage.setItem('labelReprintData', JSON.stringify(printData))

            if (selected_label === "FG/SFG Template 1") {
                window.open(`/fg-sfg-print?`, '_blank');
                window.focus();
                window.location.reload();
            }
            else if (selected_label === "FG/SFG Template 2") {
                window.open(`/fg-sfg-print1?`, '_blank');
                window.focus();
                window.location.reload();
            }
        }
        catch (error) {
            alertWarning('Please try again.');
        }

    }


    // BOC API

    async boc_print_transaction_get(production_order_id) {
        try {
            const response = await axios.get(`${BaseURL}/print-boc-get`, {
                params: { production_order_id }
                ,
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                },

            });
            return response.data;
        }
        catch (error) {
            alertWarning('Please try again.')
        }
    }

    async boc_print_transaction_create(count, data, production_order_number, production_order_qty, production_order_header_id, selected_label, navigate) {
        try {
            const payload = {
                count, data, selected_label, production_order_number, production_order_qty, production_order_header_id
            };

            const response = await axios.post(`${BaseURL}/print-boc`, payload
                , {
                    headers: {
                        Authorization: `Bearer ${getToken()}`,
                    },
                })

            localStorage.removeItem('bocPrintData');
            localStorage.removeItem('bocReprintData');
            localStorage.setItem('bocPrintData', JSON.stringify(response.data));

            if (selected_label === 'BOC Template') {
                window.open(`/boc-print`, '_blank');
                window.focus();
                window.location.reload();
            }

        }
        catch (error) {
            alertWarning('Please try again.');
        }
    }

    async boc_reprint_transaction_create(production_order_number, production_order_header_id, reason_for_reprint, selected_label, navigate) {
        try {
            const payload = {
                production_order_number, selected_label, production_order_header_id, reason_for_reprint
            };
            const response = await axios.post(`${BaseURL}/reprint-boc`, payload
                , {
                    headers: {
                        Authorization: `Bearer ${getToken()}`,
                    },
                })

            localStorage.removeItem('bocPrintData');
            localStorage.removeItem('bocReprintData');
            localStorage.setItem('bocReprintData', JSON.stringify(response.data));

            const printData = response.data;
            const queryString = new URLSearchParams({
                data: encodeURIComponent(JSON.stringify(printData))
            }).toString();

            if (selected_label === 'BOC Template') {
                window.open(`/boc-print?${queryString}`, '_blank');
                window.focus();
                window.location.reload();
            }
        }
        catch (error) {
            alertWarning('Please try again.');
        }
    }

    async pickslip_header_get(production_order_header_id) {
        try {
            const response = await axios.get(`${BaseURL}/pickslip-header-get`, {
                params: { production_order_header_id },
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                },
            });
            return response.data
        }
        catch (error) {
            alertWarning('Please try again.');
        }

    }


    // Loading Slip API

    async loading_slip_header_get(production_order_id) {
        try {
            const response = await axios.get(`${BaseURL}/loading-slip-headers/`, {
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                },
            });
            return response.data;
        }
        catch (error) {
            alertWarning('Please try again.');
        }

    }

    async loading_slip_print_transaction_get(loading_slip_header_id) {
        try {
            const response = await axios.get(`${BaseURL}/loadingslip-print-transaction-get`, {
                params: { loading_slip_header_id }
                ,
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                },
            });
            return response.data;
        }
        catch (error) {
            alertWarning('Please try again.');
        }

    }


    // Reports API

    async report_get_all_job_transaction_numbers() {
        try {
            const response = await axios.get(`${BaseURL}/reports-get-all-job-transaction-numbers`
                , {
                    headers: {
                        Authorization: `Bearer ${getToken()}`,
                    },
                }
            );
            return response.data;
        }
        catch (error) {
            alertWarning('Please try again.');
        }

    }

    async reports_get_all_with_specified_date(start_date, end_date) {
        console.log(end_date,"startdate");
        try {
            const response = await axios.get(`${BaseURL}/reports-get-all-with-specified-date`
                , {
                    params: {
                        start_date: start_date,
                        end_date: end_date,
                    },
                    headers: {
                        Authorization: `Bearer ${getToken()}`,
                    },
                }
            );
            return response.data;
        }
        catch (error) {
            alertWarning('Please try again.');
        }

    }

    async reports_get_job_transaction_number(job_transaction_id) {
        try {
            const response = await axios.get(`${BaseURL}/reports-get-job-transaction-number`, {
                params: { job_transaction_id }
                ,
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                },
            });
            return response.data;
        }
        catch (error) {
            alertWarning('Please try again.');
        }
    }

    async reports_get_part_number(part_number_id) {
        try {
            const response = await axios.get(`${BaseURL}/reports-get-part-number`, {
                params: { part_number_id }
                ,
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                },
            });

            return response.data;
        }
        catch (error) {
            alertWarning('Please try again.');

        }
    }

    async reports_get_pack_number(pack_number) {

        try {
            const response = await axios.get(`${BaseURL}/reports-get-pack-number`, {
                params: { pack_number }
                ,
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                },
            });

            return response.data;
        }
        catch (error) {
            alertWarning('Please try again.');

        }

    }

    async reports_get_loadingslip_number(loading_slip_number) {

        try {
            const response = await axios.get(`${BaseURL}/reports-get-loadingslip-number`, {
                params: { loading_slip_number }
                ,
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                },
            });

            return response.data;
        }
        catch (error) {
            alertWarning('Please try again.');

        }

    }

    async reports_get_reprint() {

        try {
            const response = await axios.get(`${BaseURL}/reports-get-reprint`, {
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                },
            });

            return response.data;
        }
        catch (error) {
            alertWarning('Please try again.');

        }

    }




}

export { API }
