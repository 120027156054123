import '../css/ErrorPage.css'
const ErrorPage = () => {
    return ( 
        <div className="errorpage">
            <p style={{"margin":"0"}}>OOPS! PAGE NOT FOUND</p>
            <h1>404</h1>
            <p style={{"margin":"0"}}>WE ARE SORRY, BUT THE PAGE YOU REQUESTED WAS <br /> NOT FOUND</p>
        </div>
     );
}
 
export default ErrorPage;