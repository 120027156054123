import { useState, useEffect } from "react";
import { QRCodeCanvas } from 'qrcode.react';

export default function FGSFGTemplate() {
    var printDataString = localStorage.getItem('printData');
    var printData = '';
    if (printDataString) {
        printData = printDataString ? JSON.parse(printDataString) : null;
    }
    else {
        printDataString = localStorage.getItem('labelReprintData');
        printData = printDataString ? JSON.parse(printDataString) : null;
    }

    // window.print();
    // window.close();
    useEffect(() => {
        // Check if print preview has already been shown
        const hasPrinted = sessionStorage.getItem('hasPrinted');

        if (!hasPrinted) {
            window.print();
            sessionStorage.setItem('hasPrinted', true);
        }

        // Automatically close and refresh page after print dialog
        const timer = setTimeout(() => {
            window.close();
            window.location.reload();
        }, 100); // Adjust the delay if needed

        return () => clearTimeout(timer);
    }, []);


    return (
        <div id='content' style={{ fontSize: '12px' }}>
            {printData.map((row, index) => (
                <div id={index} className="fg-sfg" style={{ height: '25mm', width: '60mm', pageBreakAfter: 'always', padding: '10px' }}>
                    <center>
                        <span>{row.part_description}</span> <br />
                    </center>
                    <div className="label-content" style={{ width: '60mm', display: 'flex', justifyContent: 'space-around', marginTop: '10px' }}>

                        <div class="print-area">
                            <span><b>Part#</b></span> <span> : {row.part_number} </span>    <br />
                            <span><b>JTN#</b></span> <span> : {row.job_transaction_number} </span>  <br />
                            <span><b>PO#</b></span> <span> : {row.production_order_number} </span>  <br />
                        </div>
                        <div style={{fontSize: '10px' }}>
                            <QRCodeCanvas id={`qr_code${index}`} size={60} value={row.serial_number} /> <br />
                            <span>{row.serial_number}</span>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )




}