import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Container from "react-bootstrap/esm/Container";
import Button from "react-bootstrap/esm/Button";
import DataTable from "react-data-table-component";
import { API } from "../api/API";
import { useLocation, useNavigate } from "react-router-dom";

export default function PartNumberReport() {
  const api = new API();
  const [data, setData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const part_number_id = location.state.part_number_id;
  const sales_order_number = location.state.sales_order_number;
  const production_order_number = location.state.production_order_number;
  const job_transaction_number = location.state.job_transaction_number;

  const part_number = location.state.part_number;
  const part_description = location.state.part_description;

  useEffect(() => {
    async function get_part_number() {
      const response = await api.reports_get_part_number(part_number_id);
      setData(response);
    }

    get_part_number();
  }, [part_number_id]);

  // State for search terms
  const [filters, setFilters] = useState({
    serialNumber: '',
    packNumber: '',
    loadingSlipNumber: '',
    assemblyStatus: '',
    fgStatus: '',
    loadStatus: '',
    created_at: '',
    created_by: ''
  });

  // Handle filter input changes
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));
  };

  // Filter data based on filters
  const filteredData = data.filter(row =>
    row.serial_number.toLowerCase().includes(filters.serialNumber.toLowerCase()) &&
    row.pack_number.toLowerCase().includes(filters.packNumber.toLowerCase()) &&
    row.loading_slip_number.toLowerCase().includes(filters.loadingSlipNumber.toLowerCase()) &&
    row.inward_status.toLowerCase().includes(filters.assemblyStatus.toLowerCase()) &&
    row.repack_status.toLowerCase().includes(filters.fgStatus.toLowerCase()) &&
    row.load_status.toLowerCase().includes(filters.loadStatus.toLowerCase()) &&
    row.created_at.toLowerCase().includes(filters.created_at.toLowerCase()) &&
    row.created_by.toLowerCase().includes(filters.created_by.toLowerCase()) 
    );


  const columns = [

    {
      name: (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <span>S.No.</span>
            <input
              type="text"
              name="assemblyStatus"
              // value={filters.assemblyStatus}
              // onChange={handleFilterChange}
              placeholder="S.No."
              style={{ marginTop: '3px', width: '100%', height: "20px", padding: '5px', textAlign: 'center' }}
            />
          </div>
        </>
      ),
      selector: (row, index) => filteredData.length ? index + 1 : '',
      sortable: false,
      style: {
        textAlign: "center",
      },
      width: "70px"
    },

    {
      name: (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <span>Serial Number</span>
            <input
              type="text"
              name="serialNumber"
              value={filters.serialNumber}
              onChange={handleFilterChange}
              placeholder="Serial Number"
              style={{ marginTop: '3px', marginBottom:'2px',width: '100%', height: "20px", padding: '5px', textAlign: 'center' }}
            />
          </div>
        </>
      ),
      selector: (row) => row.serial_number,
      sortable: false,
      style: {
        textAlign: "center",
      },
      width: "180px"
    },

    {
      name: (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <span>Pack Number</span>
            <input
              type="text"
              name="packNumber"
              value={filters.packNumber}
              onChange={handleFilterChange}
              placeholder="Pack Number"
              style={{ marginTop: '3px', width: '100%', height: "20px", padding: '5px', textAlign: 'center' }}
            />
          </div>
        </>
      ),
      cell: (row) => {
        return row.pack_number === 'Not yet packed.' ? 
        (
          row.pack_number
        ) :
        (
          <NavLink to={{ pathname: '/pack-number-report' }} state={{ pack_number: row.pack_number, sales_order_number: sales_order_number, production_order_number: production_order_number, job_transaction_number: job_transaction_number }}>
            {row.pack_number}
          </NavLink>
        ); 
      },
      sortable: false,
      style: {
        textAlign: "center",
      },
      width: "140px"
    },

    {
      name: (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <span>Loading Slip Number</span>
            <input
              type="text"
              name="loadingSlipNumber"
              value={filters.loadingSlipNumber}
              onChange={handleFilterChange}
              placeholder="Loading Slip Number"
              style={{ marginTop: '3px', width: '100%', height: "20px", padding: '5px', textAlign: 'center' }}
            />
          </div>
        </>
      ),
      cell: (row) => {
        return row.loading_slip_number === 'Not yet loaded.' ? 
        (
          row.loading_slip_number
        ) :
        (
          <NavLink to={{ pathname: '/loadingslip-number-report' }} state={{ loading_slip_number: row.loading_slip_number, sales_order_number: sales_order_number }}>
            {row.loading_slip_number}
          </NavLink>
        ); 
      },
      sortable: false,
      style: {
        textAlign: "center",
      },
      width: "185px"
    },

    {
      name: (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <span>Assembly Status</span>
            <input
              type="text"
              name="assemblyStatus"
              value={filters.assemblyStatus}
              onChange={handleFilterChange}
              placeholder="Assembly Status"
              style={{ marginTop: '3px', width: '100%', height: "20px", padding: '5px', textAlign: 'center' }}
            />
          </div>
        </>
      ),
      selector: (row) => filteredData.length ? (row.inward_status == "yes" ? "Completed" : "Pending") : '',
      sortable: false,
      style: {
        textAlign: "center",
      },
      width: "155px"
    },

    {
      name: (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <span>FG Status</span>
            <input
              type="text"
              name="fgStatus"
              value={filters.fgStatus}
              onChange={handleFilterChange}
              placeholder="FG Status"
              style={{ marginTop: '3px', width: '100%', height: "20px", padding: '5px', textAlign: 'center' }}
            />
          </div>
        </>
      ),
      selector: (row) => filteredData.length ? (row.repack_status == "yes" ? "Completed" : "Pending") : '',
      sortable: false,
      style: {
        textAlign: "center",
      },
      width: "125px"
    },

    {
      name: (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <span>Load Status</span>
            <input
              type="text"
              name="loadStatus"
              value={filters.loadStatus}
              onChange={handleFilterChange}
              placeholder="Load Status"
              style={{ marginTop: '3px', width: '100%', height: "20px", padding: '5px', textAlign: 'center' }}
            />
          </div>
        </>
      ),
      selector: (row) => filteredData.length ? (row.load_status == "yes" ? "Completed" : "Pending") : '',
      sortable: false,
      style: {
        textAlign: "center",
      },
      width: "135px"
    },

    {
      name: (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <span>Created By</span>
            <input
              type="text"
              name="created_by"
              value={filters.created_by}
              onChange={handleFilterChange}
              placeholder="Created By"
              style={{ marginTop: '3px', width: '100%', height: "20px", padding: '5px', textAlign: 'center' }}
            />
          </div>
        </>
      ),
      selector: (row) => row.created_by,
      sortable: false,
      style: {
        textAlign: "center",
      },
      width: "135px"
    },
    {
      name: (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <span>Created At</span>
            <input
              type="text"
              name="created_at"
              value={filters.created_at}
              onChange={handleFilterChange}
              placeholder="Created At"
              style={{ marginTop: '3px', width: '100%', height: "20px", padding: '5px', textAlign: 'center' }}
            />
          </div>
        </>
      ),
      selector: (row) => row.created_at,
      sortable: false,
      style: {
        textAlign: "center",
      },
      width: "165px"
    },
  ];

  const handleBackSubmit = () => {
    navigate(-1);
  };

  return (
    <>
      <div
        className="basic"
        style={{ position: "relative", marginTop: "30px" }}
      >
        {" "}
      </div>

      <Container>
        <span style={{ paddingRight: "30px" }}>
          <b> SO #: {sales_order_number} </b>
        </span>

        <span style={{ paddingRight: "30px" }}>
          <b> PO #: {production_order_number} </b>
        </span>
        <span style={{ paddingRight: "30px" }}>
          <b> JTN #: {job_transaction_number} </b>
        </span>

        <span style={{ paddingRight: "30px" }}>
          <b> Part #: {part_number} </b>
        </span>

        <span style={{ paddingRight: "30px" }}>
          <b> Part Description: {part_description} </b>
        </span>

        <div style={{marginBottom: '20px'}} className=""></div>


        <DataTable
          className="data-table-container"
          columns={columns}
          data={filteredData.length ? filteredData : [{}]}
          pagination
          responsive
          highlightOnHover
          dense
        />

        <Button
          variant="dark"
          onClick={handleBackSubmit}
          type="button" // Change to button to avoid submitting the form
          className="user-register-back-btn"
          size="sm"
          style={{
            backgroundColor: "#243847",
            borderColor: "#243847",
            position: "relative",
            top: "0px",
          }}
        >
          Back
        </Button>

      </Container>
    </>
  );
}
