import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import '../css/RoleMaster.css';
import { API } from '../api/API';

const EditRoleMaster = () => {
    const [role, setRole] = useState('');
    const [permissions, setPermissions] = useState({
        bom_upload: false,
        label_print: false,
        label_reprint: false,
        assembly: false,
        fg: false,
        load_process: false,
        loading_slip_print: false,
        reports: false,
    });
    const { role: roleName } = useParams(); // Assuming the role name is passed as a route parameter
    const navigate = useNavigate();
    const api = new API();

    useEffect(() => {
        async function fetchRoleData() {
            try {
                const response = await api.get_role_permissions(roleName);
                console.log('API Response:', response); // Log the entire response

                if (response && response.modules) {
                    setRole(response.role);

                    // Initialize permissions state based on API response
                    const updatedPermissions = { ...permissions };

                    // Set permissions based on API response
                    Object.keys(updatedPermissions).forEach(module => {
                        updatedPermissions[module] = response.modules.hasOwnProperty(module);
                    });

                    setPermissions(updatedPermissions);
                } else {
                    console.error('Unexpected response structure:', response);
                }
            } catch (error) {
                console.error("Error fetching role permissions:", error);
            }
        }

        fetchRoleData();
    }, [roleName]);

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setPermissions((prev) => ({
            ...prev,
            [name]: checked,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Prepare data for API request
        const modules = Object.entries(permissions).reduce((acc, [key, value]) => {
            if (value) {
                acc.push({ module_name: key, [key]: true });
            }
            return acc;
        }, []);


        const data = {
            role,
            modules, // Send only modules with true permissions
        };

        try {
            const response = await api.update_role_permissions(data, navigate);
            console.log(response);
            // Handle successful response (e.g., show a success message or navigate)
        } catch (error) {
            console.error("Error updating role permissions:", error);
            // Handle error (e.g., show an error message)
        }
    };

    const handleBackClick = () => {
        navigate("/roles");
    };

    return (
        <div className="rolemaster">
            <form onSubmit={handleSubmit}>
                <div className='role'>
                    <label>Role:
                        <input
                            type="text"
                            placeholder='Enter the role'
                            value={role}
                            onChange={(e) => setRole(e.target.value)}
                            required
                        />
                    </label>
                </div>
                <div className='modules'>
                    <table>
                        <thead>
                            <tr>
                                <th>S.no</th>
                                <th>Module Name</th>
                                <th>Permission</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(permissions).map((module, index) => (
                                <tr key={index}>
                                    <td>{index + 1}.</td>
                                    <td>{module.replace('_', ' ').toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</td>
                                    <td>
                                        <input
                                            type="checkbox"
                                            name={module}
                                            checked={permissions[module]}
                                            onChange={handleCheckboxChange}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div id="btn">
                    <button type="button" onClick={handleBackClick} className="rolemaster-back-btn">
                            Back
                        </button>
                        <button type="submit" className="rolemaster-savepermission-btn">Edit Permissions</button>   
                    </div>
                </div>
            </form>
        </div>
    );
}

export default EditRoleMaster;
