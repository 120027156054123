import React from "react";
import { Navigate } from "react-router-dom";

const ProtectPage = ({ Child, requiredPermissions = [], adminOnly = false }) => {
  const userDetailsString = localStorage.getItem("userDetails");
  const userDetails = userDetailsString ? JSON.parse(userDetailsString) : null;

  // Assuming you have a way to get user permissions, e.g., from context or props
  // For simplicity, let's assume permissions are stored in localStorage
  const permissionsString = localStorage.getItem("permissions");
  const userPermissions = permissionsString ? JSON.parse(permissionsString) : {};

  const isAdmin = userDetails?.username === 'admin1';

  const hasRequiredPermissions = requiredPermissions.every(permission => userPermissions[permission]);
  
  const isAuthorized = () => {
    if (!userDetails) return false;

    if (adminOnly && !isAdmin) return false;

    if (requiredPermissions.length > 0) {
      // return isAdmin || hasRequiredPermissions;
      return hasRequiredPermissions;
    }

    return true;
  };

  return (
    <div className="protect-page">
      {isAuthorized() ? <Child /> : <Navigate to={userDetails ? "/no-access" : "/login"} />}
    </div>
  );
};

export default ProtectPage;
