import { useState, useEffect } from "react";
import { API } from "../api/API";
import Container from "react-bootstrap/esm/Container";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import DataTable from "react-data-table-component";
import { NavLink } from "react-router-dom";

export default function ReprintReport() {
  const [data, setData] = useState({
    label_reprint_data: [],
    boc_reprint_data: [],
    repack_reprint_data: []
  });

  const [key, setKey] = useState('BOM Reprint')
  const [insideKey, setInsideKey] = useState('Label Reprint');
  const api = new API();

  useEffect(() => {
    async function get_reprint() {
      const response = await api.reports_get_reprint();      
      setData(response);
    }

    get_reprint();
  }, []);


  useEffect(() => {
    console.log(data, 'data after change');
    
  })


  const [labelReprint, setLabelReprint] = useState({
    serial_number: '',
    part_number: '',
    part_description: '',
    job_transaction_number: '',
    reason_for_reprint: '',
    created_at: '',
    created_by: ''

  });

  const [packReprint, setPackReprint] = useState({
    pack_number: '',
    job_transaction_number: '',
    reason_for_reprint: '',
    created_at: '',
    created_by: ''

  });

  const [bocReprint, setBOCReprint] = useState({
    pack_number: '',
    job_transaction_number: '',
    reason_for_reprint: '',
    created_at: '',
    created_by: ''

  });

  const handleLabelReprint = (e) => {
    const { name, value } = e.target;
    setLabelReprint(prevLabelReprint => ({
      ...prevLabelReprint,
      [name]: value
    }));
  };

  const handlePackReprint = (e) => {
    const { name, value } = e.target;
    setPackReprint(prevPackReprint => ({
      ...prevPackReprint,
      [name]: value
    }));
  };

  const handleBOCReprint = (e) => {
    const { name, value } = e.target;
    setBOCReprint(prevBOCReprint => ({
      ...prevBOCReprint,
      [name]: value
    }));
  };


    const filteredLabelReprintData = data.label_reprint_data.filter(
        row => 
            row.serial_number.toLowerCase().includes(labelReprint.serial_number.toLowerCase()) && 
            row.part_number.toLowerCase().includes(labelReprint.part_number.toLowerCase()) && 
            row.part_description.toLowerCase().includes(labelReprint.part_description.toLowerCase()) && 
            row.job_transaction_number.toLowerCase().includes(labelReprint.job_transaction_number.toLowerCase()) && 
            row.reason_for_reprint.toLowerCase().includes(labelReprint.reason_for_reprint.toLowerCase()) && 
            row.created_at.toLowerCase().includes(labelReprint.created_at.toLowerCase()) && 
            row.created_by.toLowerCase().includes(labelReprint.created_by.toLowerCase())
    );


    const filteredBOCReprintData = data.boc_reprint_data.filter(
        row =>
            row.box_number.toLowerCase().includes(bocReprint.pack_number.toLowerCase()) && 
            row.job_transaction_number.toLowerCase().includes(bocReprint.job_transaction_number.toLowerCase()) && 
            row.reason_for_reprint.toLowerCase().includes(bocReprint.reason_for_reprint.toLowerCase()) && 
            row.created_at.toLowerCase().includes(bocReprint.created_at.toLowerCase()) && 
            row.created_by.toLowerCase().includes(bocReprint.created_by.toLowerCase())
    );

    

    const filteredPackReprintData = data.repack_reprint_data.filter(row => 
      (row.pack_number).toLowerCase().includes((packReprint.pack_number || '').toLowerCase()) &&
      (row.job_transaction_number).toLowerCase().includes((packReprint.job_transaction_number || '').toLowerCase()) &&
      (row.reason_for_reprint).toLowerCase().includes((packReprint.reason_for_reprint || '').toLowerCase()) &&
      (row.created_at).toLowerCase().includes((packReprint.created_at || '').toLowerCase()) &&
      (row.created_by).toLowerCase().includes((packReprint.created_by || '').toLowerCase())
  );
  

  const label_reprint_columns = [

    {
        name: (
          <>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <span>S.No.</span>
            <input
              type="text"
              placeholder="S.No."
              // disabled
              style={{ marginTop: '3px',marginBottom:'2px', width: '100%', height:"20px",padding: '5px', textAlign: "center" }}
            />
            </div>
          </>
        ),
        selector: (row, index) => filteredLabelReprintData.length ? index + 1 : '',
        sortable: false,
        style: {
          textAlign: "center",
        },
        width: "70px"
    },

    {
        name: (
          <>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <span>Serial Number</span>
              <input
                type="text"
                name="serial_number"
                value={labelReprint.serial_number}
                onChange={handleLabelReprint}
                placeholder="Serial Number"
                style={{ marginTop: '3px', marginBottom:'2px',width: '100%', height: "20px", padding: '5px', textAlign: "center" }}
              />
            </div>
          </>
        ),
        selector: (row) => row.serial_number,
        sortable: false,
        style: {
          textAlign: "center",
        },
        cell: row => (
          <div title={row.serial_number} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {row.serial_number}
          </div>
        ),
    },

    {
    name: (
        <>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <span>Part Number</span>

            <input
            type="text"
            name="part_number"
            value={labelReprint.part_number}
            onChange={handleLabelReprint}
            placeholder="Part Number"
            style={{ marginTop: '3px',marginBottom:'2px', width: '100%', height: "20px", padding: '5px', textAlign:"center" }}
            />
        </div>
        </>
    ),
    selector: (row) => row.part_number,
    sortable: true,
    style: {
        textAlign: "center",
    },
    cell: row => (
      <div title={row.part_number} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {row.part_number}
      </div>
    ),
    },

    {
    name: (
        <>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <span>Part Description</span>

            <input
            type="text"
            name="part_description"
            value={labelReprint.part_description}
            onChange={handleLabelReprint}
            placeholder="Part Description"
            style={{ marginTop: '3px',marginBottom:'2px', width: '100%', height: "20px", padding: '5px', textAlign: "center" }}
            />
        </div>
        </>
    ),
    selector: (row) => row.part_description,
    sortable: true,
    style: {
        textAlign: "center",
    },
        width: "165px",
        cell: row => (
          <div title={row.part_description} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {row.part_description}
          </div>
        ),
    },

    {
        name: (
          <>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <span>JTN #</span>
  
              <input
                type="text"
                name="job_transaction_number"
                value={labelReprint.job_transaction_number}
                onChange={handleLabelReprint}
                placeholder="Job Tracking Number"
                style={{ marginTop: '3px',marginBottom:'2px', width: '100%', height: "20px", padding: '5px', textAlign: "center" }}
              />
            </div>
          </>
        ),
        selector: (row) => row.job_transaction_number,
        sortable: true,
        style: {
          textAlign: "center",
        },
        cell: row => (
          <div title={row.job_transaction_number} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {row.job_transaction_number}
          </div>
        ),
    },

    {
        name: (
            <>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <span>Reason</span>
    
                <input
                type="text"
                name="reason_for_reprint"
                value={labelReprint.reason_for_reprint}
                onChange={handleLabelReprint}
                placeholder="Reason"
                style={{ marginTop: '3px',marginBottom:'2px', width: '100%', height: "20px", padding: '5px', textAlign: "center" }}
                />
            </div>
            </>
        ),
        selector: (row) => row.reason_for_reprint,
        sortable: true,
        style: {
            textAlign: "center",
        },
        cell: row => (
          <div title={row.reason_for_reprint} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {row.reason_for_reprint}
          </div>
        ),
    },
    {
      name: (
          <>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <span>Created By</span>
  
              <input
              type="text"
              name="created_by"
              value={labelReprint.created_by}
              onChange={handleLabelReprint}
              placeholder="Created By"
              style={{ marginTop: '3px',marginBottom:'2px',width: '100%', height: "20px", padding: '5px', textAlign: "center" }}
              />
          </div>
          </>
      ),
      selector: (row) => row.created_by,
      sortable: true,
      style: {
          textAlign: "center",
      },
      width: "135px"
      },
    {
        name: (
          <>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <span>Created at</span>
  
              <input
                type="text"
                name="created_at"
                value={labelReprint.created_at}
                onChange={handleLabelReprint}
                placeholder="Created at"
                style={{ marginTop: '3px',marginBottom:'2px',width: '100%', height: "20px", padding: '5px', textAlign: "center" }}
              />
            </div>
          </>
        ),
        selector: (row) => row.created_at,
        sortable: true,
        style: {
          textAlign: "center",
        },
        width: "165px"
    },

  ];

  const boc_reprint_columns = [

    {
        name: (
          <>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <span>S.No.</span>
            <input
              type="text"
              placeholder="S.No."
              // disabled
              style={{ marginTop: '3px',marginBottom:'2px', width: '100%', height:"20px",padding: '5px', textAlign: "center" }}
            />
            </div>
          </>
        ),
        selector: (row, index) => filteredBOCReprintData.length ? index + 1 : '',
        sortable: false,
        style: {
          textAlign: "center",
        },
        width: "70px"
    },

    {
        name: (
          <>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <span>Box Number</span>
              <input
                type="text"
                name="pack_number"
                value={bocReprint.pack_number}
                onChange={handleBOCReprint}
                placeholder="Pack Number"
                style={{ marginTop: '3px', width: '100%', height: "20px", padding: '5px', textAlign:"center" }}
              />
            </div>
          </>
        ),
        selector: (row) => row.box_number,
        sortable: false,
        style: {
          textAlign: "center",
        },
    },

    {
        name: (
          <>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <span>JTN #</span>
  
              <input
                type="text"
                name="job_transaction_number"
                value={bocReprint.job_transaction_number}
                onChange={handleBOCReprint}
                placeholder="Job Tracking Number"
                style={{ marginTop: '3px',marginBottom:'2px', width: '100%', height: "20px", padding: '5px', textAlign: "center" }}
              />
            </div>
          </>
        ),
        selector: (row) => row.job_transaction_number,
        sortable: true,
        style: {
          textAlign: "center",
        },
    },

    {
        name: (
            <>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <span>Reason</span>
    
                <input
                type="text"
                name="reason_for_reprint"
                value={bocReprint.reason_for_reprint}
                onChange={handleBOCReprint}
                placeholder="Reason"
                style={{ marginTop: '3px',marginBottom:'2px', width: '100%', height: "20px", padding: '5px', textAlign: "center" }}
                />
            </div>
            </>
        ),
        selector: (row) => row.reason_for_reprint,
        sortable: true,
        style: {
            textAlign: "center",
        },
    },
  
    {
    name: (
        <>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <span>Created By</span>

            <input
            type="text"
            name="created_by"
            value={bocReprint.created_by}
            onChange={handleBOCReprint}
            placeholder="Created By"
            style={{ marginTop: '3px', marginBottom:'2px',width: '100%', height: "20px", padding: '5px', textAlign: "center" }}
            />
        </div>
        </>
    ),
    selector: (row) => row.created_by,
    sortable: true,
    style: {
        textAlign: "center",
    },
    width: "135px"
    },
    {
      name: (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <span>Created at</span>

            <input
              type="text"
              name="created_at"
              value={bocReprint.created_at}
              onChange={handleBOCReprint}
              placeholder="Created at"
              style={{ marginTop: '3px',marginBottom:'2px',width: '100%', height: "20px", padding: '5px', textAlign: "center" }}
            />
          </div>
        </>
      ),
      selector: (row) => row.created_at,
      sortable: true,
      style: {
        textAlign: "center",
      },
      width: "165px"
  },

  ];

  const pack_reprint_columns = [

    {
        name: (
          <>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <span>S.No.</span>
            <input
              type="text"
              placeholder="S.No."
              // disabled
              style={{ marginTop: '3px',marginBottom:'2px', width: '100%', height:"20px",padding: '5px', textAlign: "center" }}
            />
            </div>
          </>
        ),
        selector: (row, index) => filteredPackReprintData.length ? index + 1 : '',
        sortable: false,
        style: {
          textAlign: "center",
        },
        width: "70px"
    },

    {
        name: (
          <>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <span>Pack Number</span>
              <input
                type="text"
                name="pack_number"
                value={packReprint.pack_number}
                onChange={handlePackReprint}
                placeholder="Pack Number"
                style={{ marginTop: '3px', width: '100%', height: "20px", padding: '5px', textAlign: "center" }}
              />
            </div>
          </>
        ),
        selector: (row) => <div title={row.pack_number}>
        {row.pack_number}
    </div>,
        sortable: false,
        style: {
          textAlign: "center",
        },
        cell: row => (
          <div title={row.pack_number} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {row.pack_number}
          </div>
        ),
    },

    {
        name: (
          <>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <span>JTN #</span>
  
              <input
                type="text"
                name="job_transaction_number"
                value={packReprint.job_transaction_number}
                onChange={handlePackReprint}
                placeholder="Job Tracking Number"
                style={{ marginTop: '3px',marginBottom:'2px', width: '100%', height: "20px", padding: '5px', textAlign: "center" }}
              />
            </div>
          </>
        ),
        selector: (row) => row.job_transaction_number,
        sortable: true,
        style: {
          textAlign: "center",
        },
    },

    {
        name: (
            <>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <span>Reason</span>
    
                <input
                type="text"
                name="reason_for_reprint"
                value={packReprint.reason_for_reprint}
                onChange={handlePackReprint}
                placeholder="Reason"
                style={{ marginTop: '3px',marginBottom:'2px', width: '100%', height: "20px", padding: '5px', textAlign: "center" }}
                />
            </div>
            </>
        ),
        selector: (row) => row.reason_for_reprint,
        sortable: true,
        style: {
            textAlign: "center",
        },
    },
  
    {
    name: (
        <>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <span>Created By</span>

            <input
            type="text"
            name="created_by"
            value={packReprint.created_by}
            onChange={handlePackReprint}
            placeholder="Created By"
            style={{ marginTop: '3px',marginBottom:'2px',width: '100%', height: "20px", padding: '5px', textAlign: "center" }}
            />
        </div>
        </>
    ),
    selector: (row) => row.created_by,
    sortable: true,
    style: {
        textAlign: "center",
    },
    width: "135px"
    },
    {
      name: (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <span>Created at</span>

            <input
              type="text"
              name="created_at"
              value={packReprint.created_at}
              onChange={handlePackReprint}
              placeholder="Created at"
              style={{ marginTop: '3px',marginBottom:'2px',width: '100%', height: "20px", padding: '5px', textAlign: "center" }}
            />
          </div>
        </>
      ),
      selector: (row) => <div title={row.created_at}>
      {row.created_at}
  </div> ,
      sortable: true,
      style: {
        textAlign: "center",
      },
      width: "155px"
  },

  ];


  return (
    <>
    <div className="basic" style={{ position: "relative", marginTop: "20px" }}></div>
      <Container>
        <Tabs
          defaultActiveKey="BOM Reprint"
          id="uncontrolled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >  
          
          <Tab eventKey="BOM Reprint" title="BOM Reprint">

            <Tabs
            defaultActiveKey="Label Reprint"
            id="uncontrolled-tab-example"
            activeKey={insideKey}
            onSelect={(k) => setInsideKey(k)}
            className="mb-3"
            >

                <Tab eventKey='Label Reprint' title='Label Reprint'>
                    <DataTable
                        className="data-table-container"
                        columns={label_reprint_columns}
                        data={filteredLabelReprintData.length ? filteredLabelReprintData : [{}]}
                        pagination
                        responsive
                        highlightOnHover
                        dense
                    />
                </Tab>


                <Tab eventKey='BOC Reprint' title='BOC Reprint'>
                    <DataTable
                        className="data-table-container"
                        columns={boc_reprint_columns}
                        data={filteredBOCReprintData.length ? filteredBOCReprintData : [{}]}
                        pagination
                        responsive
                        highlightOnHover
                        dense
                    />
                </Tab>

            </Tabs>
        
          </Tab>

          <Tab eventKey="FG Inward Reprint" title="Assembly Reprint">

          <DataTable
            className="data-table-container"
            columns={pack_reprint_columns}
            data={filteredPackReprintData.length ? filteredPackReprintData : [{}]}
            pagination
            responsive
            highlightOnHover
            dense
            />
            
          </Tab>


        </Tabs>
      </Container>
    </>
  );
}
