import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { API } from '../api/API';
import { alertSuccessLogin, alertWarning } from '../alert';

const IdleTimeOut = ({ children }) => {

  const api = new API();
  const navigate = useNavigate();
  let logoutTimer;

  const logout = async () => {
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem("userDetails"));
  
    try {
      const response = await api.user_auth_logout(user.id);
  
      if (response.status === 200) {
        // Clear token and show a success message
        console.log(response.data.message);
        const message = "Logged out due to inactivity. Please log in again.";
        localStorage.removeItem('token');
        localStorage.removeItem('userDetails');
        alertSuccessLogin(message, navigate, "/login");
        navigate('/login')
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Handle unauthenticated state
        alertWarning(error.response.data.error || 'An error occurred while logging out');
      } else {
        alertWarning('An error occurred while logging out');
      }
    }
  };

  const resetTimer = () => {
    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
    // Set the logout timer to 5 minutes (300,000 ms)
    logoutTimer = setTimeout(logout, 3600000);
  };

  useEffect(() => {
    const events = ['mousemove', 'keydown', 'click', 'scroll', 'touchstart'];

    events.forEach((event) =>
      window.addEventListener(event, resetTimer)
    );

    // Start the initial timer
    resetTimer();

    // Cleanup event listeners on component unmount
    return () => {
      events.forEach((event) =>
        window.removeEventListener(event, resetTimer)
      );
      if (logoutTimer) {
        clearTimeout(logoutTimer);
      }
    };
  }, []);

  return <>{children}</>;
};

export default IdleTimeOut;
