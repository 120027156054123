import React, { useState } from "react";
import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom"; // Import Link from react-router-dom

const pages = [
  { name: "BOM Upload", path: "/bom-upload" },
  { name: "Label Print", path: "/label-print-index" },
  { name: "Label RePrint", path: "/label-reprint-index" },
  { name: "Loading Slip Print", path: "/loading-slip-print-index" },
  { name: "Reports", path: "/reports" },
  {name: "User", path: "/users"},
  {name: "Role", path: "/roles"},
];

const Sidebar = () => {
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <>
      <Drawer
        anchor="left"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <List>
          {pages.map((page, index) => (
            <ListItemButton
              component={Link} // Use Link component for routing
              to={page.path} // Set the path to navigate to
              key={index}
            >
              <ListItemText primary={page.name} />
            </ListItemButton>
          ))}
        </List>
      </Drawer>
      <IconButton
        sx={{ color: "white", marginLeft: "auto" }}
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <MenuIcon color="white" />
      </IconButton>
    </>
  );
};

export default Sidebar;
