import { useEffect } from "react";
import { QRCodeCanvas } from 'qrcode.react';

export default function BOCTemplate() {
    var printDataString = localStorage.getItem('bocPrintData');
    var printData = '';
    if (printDataString) {
        printData = printDataString ? JSON.parse(printDataString) : null;
    } else {
        printDataString = localStorage.getItem('bocReprintData');
        printData = printDataString ? JSON.parse(printDataString) : null;
    }

    useEffect(() => {
        // Check if print preview has already been shown
        const hasPrinted = sessionStorage.getItem('hasPrinted');

        if (!hasPrinted) {
            window.print();
            sessionStorage.setItem('hasPrinted', true);
        }

        // Automatically close and refresh page after print dialog
        const timer = setTimeout(() => {
            window.close();
            window.location.reload();
        }, 500); // Adjust the delay if needed

        return () => clearTimeout(timer);
    }, []);

    return (
        <div id='content' style={{ fontSize: '12px' }}>
            {printData.map((row, index) => (
                <div key={index} className="fg-sfg" style={{ height: '50mm', width: '75mm', pageBreakAfter: 'always', padding: '10px' }}>
                    <div className="label-content" style={{ width: '75mm', display: 'flex', justifyContent: 'space-around', marginTop: '10px' }}>
                        <div class="print-area">
                            <span><b>JTN#</b></span> <span> : {row.job_transaction_number} </span><br />
                            <span><b>PO#</b></span> <span> : {row.production_order_number} </span><br />
                            <span><b>SO#</b></span> <span> : {row.sales_order_number} </span><br />
                            <span><b>PR#</b></span> <span> : {row.reference} </span><br />
                        </div>
                        <div style={{ marginTop: '10px', fontSize: '10px', }}>
                            <QRCodeCanvas size={60} value={row.pack_number} /><br />
                            <span>{row.pack_number}</span>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}
