import { useState, useEffect } from "react";
import { QRCodeCanvas } from 'qrcode.react';

export default function FGSFGTemplate() {
    var printDataString = localStorage.getItem('printData');
    var printData = '';
    if (printDataString) {
        printData = printDataString ? JSON.parse(printDataString) : null;
    } else {
        printDataString = localStorage.getItem('labelReprintData');
        printData = printDataString ? JSON.parse(printDataString) : null;
    }

    useEffect(() => {
        // Check if print preview has already been shown
        const hasPrinted = sessionStorage.getItem('hasPrinted');

        if (!hasPrinted) {
            window.print();
            sessionStorage.setItem('hasPrinted', true);
        }

        // Automatically close and refresh page after print dialog
        const timer = setTimeout(() => {
            window.close();
            window.location.reload();
        }, 500); // Adjust the delay if needed

        return () => clearTimeout(timer);
    }, []);

    return (
        <div id='content' style={{fontSize: '12px'}}>
            {printData && printData.map((row, index) => (
                <div key={index} className="fg-sfg" style={{ height: '50mm', width: '75mm', pageBreakAfter: 'always', padding: '10px' }}>
                    <center>
                        <span className="description" style={{ textAlign: 'center' }}>{row.part_description}</span>
                    </center>

                    <div className="label-content" style={{ width: '75mm', display: 'flex', justifyContent: 'space-around', marginTop: '10px' }}>
                        <div class="print-area">
                            <span><strong>Part#:</strong> {row.part_number}</span> <br />
                            <span><strong>JTN#:</strong> {row.job_transaction_number}</span> <br />
                            <span><strong>PO#:</strong> {row.production_order_number}</span> <br />
                            <span><strong>SO#:</strong> {row.sales_order_number}</span> <br />
                            <span><strong>PR#:</strong> {row.reference}</span>
                        </div>
                        <div className="qr-section" style={{fontSize: '10px', marginTop: '10px' }}>
                            <QRCodeCanvas id={`qr_code${index}`} size={60} value={row.serial_number} /> <br />
                            <span>{row.serial_number}</span>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}
