import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Container from "react-bootstrap/esm/Container";
import Table from "react-bootstrap/esm/Table";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/esm/Button";
import { API } from "../api/API.js";
import { alertSuccess, alertWarning } from "../alert.js";
import "../css/custom.css";

export default function LabelPrint() {
  const location = useLocation();
  const navigate = useNavigate();

  const api = new API();
  const productionOrderID = location.state?.productionOrderID;
  const productionOrderNumber = location.state?.productionOrderNumber;
  const jobTransactionNumber = location.state?.jobTransactionNumber;
  const productionOrderQty = location.state?.productionOrderQty;

  const [selectedLabel, setSelectedLabel] = useState("");

  const type = "checkbox";

  const [key, setKey] = useState("FG/SFG");
  const [selected, setSelected] = useState([]);
  const [printData, setPrintData] = useState([]);
  const [count, setCount] = useState(0);
  const [checked, setChecked] = useState(false);
  const [trigger, setTrigger] = useState(false);

  const [data, setData] = useState({
    transaction_data_fg_sfg: [],
    transaction_data_boc: [],
    print_status: "",
    inward_status: "",
    status: "",
    label_print_status: "",
    boc_print_status: "",
    labels: [],
  });

  const handleLabelCountChange = (event) => {
    setCount(event.target.value);
  };

  const handleHeaderCheckBoxFGSFG = (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelected([]);
      setPrintData([]);

      const enabledRows = data.transaction_data_fg_sfg.filter(
        (row) => row.print_status !== "yes"
      );

      setChecked(true);
      setSelected(enabledRows.map((row) => row.id));
      setPrintData(enabledRows);
    } else {
      setSelected([]);
      setPrintData([]);
      setChecked(false);
    }
  };

  const handleCheckBoxSFGFG = (event, row) => {
    console.log(row, 'row');
    
    const isChecked = event.target.checked;
    const updateSelected = isChecked ? [...selected, row.id] : selected.filter((item) => item !== row.id);
    const updatePrintData = isChecked ? [...printData, row] : printData.filter((item) => item.id !== row.id);

    setSelected(updateSelected);
    setPrintData(updatePrintData);
  };

  useEffect(() => {
    console.log(selected, 'selected');
    
    const enabledRows = data.transaction_data_fg_sfg.filter(
      (row) => row.print_status !== "yes"
    );
    if (selected.length === enabledRows.length && selected.length !== 0) {
      console.log('in1');
      
      setChecked(true);
    } else {
      console.log('in2');
      setChecked(false);
    }
  }, [selected]);

  const handleSubmitSFGFG = (event) => {
    event.preventDefault();
    if (selected.length === 0 || printData.length === 0) {
      alertWarning("Please select at least one checkbox to print.");
    }
    else if (selectedLabel.length === 0) {
      alertWarning("Please select any Label Template to print.");
    } else {
      setTrigger((prev) => !prev);
      api.label_print_transaction_create(
        printData,
        productionOrderNumber,
        productionOrderQty,
        jobTransactionNumber,
        selectedLabel,
        navigate
      );
    }
  };

  const handleSubmitBOC = (event) => {
    event.preventDefault();
    if (count < 1) {
      alertWarning("Please enter a valid label count.");
    }
    else if (selectedLabel.length === 0) {
      alertWarning("Please select any Label Template to print.");
    } else {
      api.boc_print_transaction_create(
        count,
        data.transaction_data_boc,
        productionOrderNumber,
        data.production_order_qty,
        productionOrderID,
        selectedLabel,
        navigate
      );
    }
  };

  const handleSubmitPickslip = (event) => {
    event.preventDefault();

    const propData = {
      table_data: data.transaction_data_boc,
      job_transaction_number: jobTransactionNumber,
      production_order_number: productionOrderNumber,
      production_order_header_id: productionOrderID,
    };

    localStorage.removeItem("pickslipData");
    localStorage.setItem("pickslipData", JSON.stringify(propData));
    window.open("/pickslip-print", "_blank");
  };

  useEffect(() => {
    async function get() {
      const transaction = await api.production_transaction_get(
        productionOrderID
      );
      setData(transaction);
    }

    get();
  }, [productionOrderID, jobTransactionNumber, productionOrderQty, trigger]);

  const handleBackSubmit = () => {
    navigate("/label-print-index");
  };

  const handleChange = (event) => {
    setSelectedLabel(event.target.value);
  };

  return (
    <>
      <div className="basic" style={{ position: "relative", marginTop: "1px" }}>
        <Container>
          <br />
          <span style={{ paddingRight: "30px" }}>
            <b> Production Order Number: {productionOrderNumber} </b>
          </span>
          <span style={{ paddingRight: "30px" }}>
            <b> Job Tracking Number: {jobTransactionNumber} </b>
          </span>
          <span style={{ paddingRight: "30px" }}>
            <b> Production Order Quantity: {productionOrderQty} </b>
          </span>
          <span>
            <b>
              {" "}
              Print Status:{" "}
              {data.print_status === "yes" ? "Completed" : "Pending"}{" "}
            </b>
          </span>

          <Tabs
            defaultActiveKey="FG/SFG"
            id="uncontrolled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="custom-mb mb-3"
          >
            {/* FG/SFG Tab */}
            <Tab eventKey="FG/SFG" title="FG/SFG">
              {data.transaction_data_fg_sfg.length > 0 && (
                <Table responsive className="custom-table">
                  <thead>
                    <tr>
                      <th>
                        <Form.Check
                          className="custom-checkbox"
                          type={type}
                          disabled={data.label_print_status ? true : false}
                          checked={checked}
                          onChange={handleHeaderCheckBoxFGSFG}
                        />
                      </th>
                      <th>Item Type</th>
                      <th>Item Number</th>
                      <th>Item Description</th>
                      <th>Item Level</th>
                      <th>Item Quantity</th>
                      <th>Item UOM</th>
                    </tr>
                  </thead>

                  <tbody>
                    {data.transaction_data_fg_sfg.map((row) => (
                      <tr key={row.id}>
                        <td>
                          <Form.Check
                            type={type}
                            checked={
                              selected.includes(row.id) &&
                              row.print_status === "no"
                            }
                            disabled={row.print_status === "yes"}
                            onChange={(event) =>
                              handleCheckBoxSFGFG(event, row)
                            }
                          />
                        </td>
                        <td>{row.item_type}</td>
                        <td>{row.item_number}</td>
                        <td>{row.item_description}</td>
                        <td>{row.item_level}</td>
                        <td>{row.item_qty}</td>
                        <td>{row.uom}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}

              <Form.Select
                style={{ width: "200px", position: "absolute", right: "90px" }}
                value={selectedLabel}
                onChange={handleChange}
                required
              >
                <option value="" disabled>
                  Select a Label
                </option>
                {data.labels
                  .filter((label) => label.label_type === "FG/SFG")
                  .map((label) => (
                    <option key={label.id} value={label.label_name}>
                      {label.label_name}
                    </option>
                  ))}
              </Form.Select>

              <Button
                variant="dark"
                onClick={handleBackSubmit}
                type="button"
                className="user-register-back-btn"
                size="sm"
                style={{
                  backgroundColor: "#243847",
                  borderColor: "#243847",
                  position: "relative",
                  top: "60px",
                }}
              >
                Back
              </Button>

              {!data.label_print_status && (
                <div className="right-align">
                  <Button
                    variant="dark"
                    type="submit"
                    className="custom-button"
                    size="sm"
                    onClick={handleSubmitSFGFG}
                    style={{
                      backgroundColor: "#243847",
                      borderColor: "#243847",
                    }}
                  >
                    Print FG/SFG labels
                  </Button>
                </div>
              )}
            </Tab>

            {/* BOC Tab */}
            {data.transaction_data_boc.length > 0 && (
            <Tab eventKey="BOC" title="BOC">
              <Table responsive className="custom-table">
                <thead>
                  <tr>
                    <th style={{width: '100px'}}>Item Type</th>
                    <th style={{width: '130px'}}>Item Number</th>
                    <th>Item Description</th>
                    <th style={{width: '100px'}}>Item Level</th>
                    <th style={{width: '130px'}}>Item Quantity</th>
                    <th style={{width: '100px'}}>Item UOM</th>
                  </tr>
                </thead>
                <tbody>
                  {data.transaction_data_boc.map((row) => (
                    <tr key={row.id}>
                      <td>{row.item_type}</td>
                      <td>{row.item_number}</td>
                      <td>{row.item_description}</td>
                      <td>{row.item_level}</td>
                      <td>{row.item_qty}</td>
                      <td>{row.uom}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <Form.Select
                style={{ width: "200px", position: "absolute", right: "90px" }}
                value={selectedLabel}
                onChange={handleChange}
                required
              >
                <option value="" disabled>
                  Select a Label
                </option>
                {data.labels
                  .filter((label) => label.label_type === "BOC")
                  .map((label) => (
                    <option key={label.id} value={label.label_name}>
                      {label.label_name}
                    </option>
                  ))}
              </Form.Select>

              <Form.Group controlId="labelCount">
                {/* <Form.Label>Label Count</Form.Label> */}
                <Form.Control
                  type="number"
                  name="boc_label_count"
                  className="custom-input2  "
                  placeholder="Label Count"
                  min="1"
                  onChange={handleLabelCountChange}
                  required
                />
              </Form.Group>

              <Button
                variant="dark"
                onClick={handleBackSubmit}
                type="button"
                className="user-register-back-btn"
                size="sm"
                style={{
                  backgroundColor: "#243847",
                  borderColor: "#243847",
                  position: "relative",
                  top: "40px",
                }}
              >
                Back
              </Button>


              <Button
                style={{ backgroundColor: '#243847', borderColor: '#243847', position: "absolute", right: "86px", top: "50px", padding: "2px 5px" }}
                onClick={handleSubmitPickslip}
                variant="dark"
                type="submit"
              // className="custom-button"
              // size="sm"
              >
                Print Pickslip
              </Button>

              {!data.boc_print_status && (
                <div className="right-align">
                  <Button
                    variant="dark"
                    type="submit"
                    className="custom-button"
                    size="sm"
                    onClick={handleSubmitBOC}
                    style={{
                      backgroundColor: "#243847",
                      borderColor: "#243847",
                      position: "relative",
                      bottom: "20px"
                    }}
                  >
                    Print BOC labels
                  </Button>
                </div>
              )}
            </Tab>
            )}
          </Tabs>
        </Container>
         <br /> 
         <br />
      </div>
    </>
  );
}
