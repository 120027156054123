import { API } from "../api/API";
import { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import Container from "react-bootstrap/esm/Container";
import { NavLink } from "react-router-dom";
import * as XLSX from "xlsx"; // Import XLSX for Excel export
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { IconButton } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import RestoreIcon from '@mui/icons-material/Restore';
import RefreshIcon from '@mui/icons-material/Refresh'
import Button from "react-bootstrap/Button";
import { alertWarning } from "../alert";

export default function Report() {
  const [data, setData] = useState([]);
  const api = new API();
  // const [filteredData, setFilteredData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    async function get_all_job_transaction_numbers() {
      const response = await api.report_get_all_job_transaction_numbers();
      setData(response);
      // setFilteredData(response);
    }

    get_all_job_transaction_numbers();
  }, []);


  const normalizeDate = (dateString) => {
    // Convert the date string to a Date object
    const date = new Date(dateString);

    // Return the date in YYYY-MM-DD format
    return date.toISOString().split('T')[0]; // Returns 'YYYY-MM-DD'
  };


  const filterData = async () => {
    // Format startDate and endDate
    if (!startDate || !endDate) {
      alertWarning("Please select start and end dates");
      return;
    }

    const formattedStartDate = normalizeDate(startDate);
    const formattedEndDate = normalizeDate(endDate);

    const start = formattedStartDate;
    const end = formattedEndDate;

    if (start > end) {
      alertWarning("Start date can't be after end date");
      return;
    }

    try {
      const filteredTransactions = await api.reports_get_all_with_specified_date(
        formattedStartDate,
        formattedEndDate);
      setData(filteredTransactions);

    } catch (error) {
      console.error("Error fetching filtered transactions:", error);
      alertWarning("Failed to fetch filtered data. Please try again.");
    }
  };


  const handleExport = () => {
    const ws = XLSX.utils.json_to_sheet(mappedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Reports");
    XLSX.writeFile(wb, "Reports.xlsx");
  };


  // State for search terms
  const [filters, setFilters] = useState({
    salesOrderNumber: '',
    productionOrderNumber: '',
    jobTransactionNumber: '',
    partNumber: '',
    partDescription: '',
    printStatus: '',
    created_at: '',
    created_by: '',
  });

  // Handle filter input changes
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));
  };

  // Filter data based on filters, ensure data is defined and not empty
  const filteredData = (data && data.length > 0 ? data : []).filter(row =>
    row.active_status === "active" &&
    row.sales_order_number?.toLowerCase().includes(filters.salesOrderNumber.toLowerCase()) &&
    row.production_order_number?.toLowerCase().includes(filters.productionOrderNumber.toLowerCase()) &&
    row.job_transaction_number?.toLowerCase().includes(filters.jobTransactionNumber.toLowerCase()) &&
    row.part_number?.toLowerCase().includes(filters.partNumber.toLowerCase()) &&
    row.part_description?.toLowerCase().includes(filters.partDescription.toLowerCase()) &&
    row.status?.toLowerCase().includes(filters.printStatus.toLowerCase()) &&
    row.created_at?.includes(filters.created_at) &&
    row.created_by?.toLowerCase().includes(filters.created_by.toLowerCase())
  );

  const mappedData = filteredData.map((row, index) => {
    return {
      'S.No': index + 1,
      'Sales Order Number': row.sales_order_number,
      'Production Order Number': row.production_order_number,
      'Job Tracking Number': row.job_transaction_number,
      'Part Number': row.part_number,
      'Part Description': row.part_description,
      'Status': row.status,
      'Created At': row.created_at,
      'Created By': row.created_by
    };
  });



  const columns = [
    {
      name: (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <span>S.No.</span>
            <input
              type="text"
              placeholder="S.No."
              // disabled
              style={{ marginTop: '3px', marginBottom: '2px', width: '100%', height: "20px", padding: '5px' }}
            />
          </div>
        </>
      ),
      selector: (row, index) => filteredData.length ? index + 1 : '',
      sortable: false,
      style: {
        textAlign: "center",
      },
      width: "70px"
    },

    {
      name: (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <span>SO #</span>
            <input
              type="text"
              name="salesOrderNumber"
              value={filters.salesOrderNumber}
              onChange={handleFilterChange}
              placeholder="Sales Order"
              style={{ marginTop: '3px', marginBottom: '2px', width: '100%', height: "20px", padding: '5px' }}
            />
          </div>
        </>
      ),
      selector: (row) => row.sales_order_number,
      sortable: true,
      style: {
        textAlign: "center",
      },
      width: "120px",
      cell: row => (
        <div title={row.sales_order_number} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {row.sales_order_number}
        </div>
      ),
    },

    {
      name: (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <span>PO #</span>

            <input
              type="text"
              name="productionOrderNumber"
              value={filters.productionOrderNumber}
              onChange={handleFilterChange}
              placeholder="Production Order"
              style={{ marginTop: '3px', marginBottom: '2px', width: '100%', height: "20px", padding: '5px' }}
            />
          </div>
        </>
      ),
      selector: (row) => row.production_order_number,
      sortable: true,
      style: {
        textAlign: "center",
      },
      width: "120px",
      cell: row => (
        <div title={row.production_order_number} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {row.production_order_number}
        </div>
      ),
    },

    {
      name: (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <span>JTN #</span>

            <input
              type="text"
              name="jobTransactionNumber"
              value={filters.jobTransactionNumber}
              onChange={handleFilterChange}
              placeholder="Job Tracking Number"
              style={{ marginTop: '3px', marginBottom: '2px', width: '100%', height: "20px", padding: '5px' }}
            />
          </div>
        </>
      ),
      selector: (row) => <NavLink to='/detailed-report' state={{ jobTransactionID: row.id, salesOrderNumber: row.sales_order_number, productionOrderNumber: row.production_order_number, jobTransactionNumber: row.job_transaction_number }}>{row.job_transaction_number}</NavLink>,
      sortable: true,
      style: {
        textAlign: "center",
      },
      width: "120px",
      // cell: row => (
      //   <div title={row.job_transaction_number} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
      //     {row.job_transaction_number}
      //   </div>
      // ),
    },

    {
      name: (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <span>Part Number</span>

            <input
              type="text"
              name="partNumber"
              value={filters.partNumber}
              onChange={handleFilterChange}
              placeholder="Part Number"
              style={{ marginTop: '3px', marginBottom: '2px', width: '100%', height: "20px", padding: '5px' }}
            />
          </div>
        </>
      ),
      selector: (row) => row.part_number,
      sortable: true,
      style: {
        textAlign: "center",
      },
      width: "140px",
      cell: row => (
        <div title={row.part_number} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {row.part_number}
        </div>
      ),
    },

    {
      name: (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <span>Part Description</span>

            <input
              type="text"
              name="partDescription"
              value={filters.partDescription}
              onChange={handleFilterChange}
              placeholder="Part Description"
              style={{ marginTop: '3px', marginBottom: '2px', width: '100%', height: "20px", padding: '5px' }}
            />
          </div>
        </>
      ),
      selector: (row) => row.part_description,
      sortable: true,
      style: {
        textAlign: "center",
      },
      width: "160px",
      cell: row => (
        <div title={row.part_description} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {row.part_description}
        </div>
      ),
    },

    {
      name: (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <span>Status</span>

            <input
              type="text"
              name="printStatus"
              value={filters.printStatus}
              onChange={handleFilterChange}
              placeholder="Status"
              style={{ marginTop: '3px', marginBottom: '2px', width: '100%', height: "20px", padding: '5px' }}
            />
          </div>
        </>
      ),
      selector: (row) => row.status,
      sortable: true,
      style: {
        textAlign: "center",
      },
      width: "110px"
    },

    {
      name: (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <span>Created By</span>

            <input
              type="text"
              name="created_by"
              value={filters.created_by}
              onChange={handleFilterChange}
              placeholder="Created By"
              style={{ marginTop: '3px', marginBottom: '2px', width: '100%', height: "20px", padding: '5px' }}
            />
          </div>
        </>
      ),
      selector: (row) => row.created_by,
      sortable: true,
      style: {
        textAlign: "center",
      },
      width: "125px"
    },
    {
      name: (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <span>Created at</span>

            <input
              type="text"
              name="created_at"
              value={filters.created_at}
              onChange={handleFilterChange}
              placeholder="Created at"
              style={{ marginTop: '3px', marginBottom: '2px', width: '100%', height: "20px", padding: '5px', textAlign: "center" }}
            />
          </div>
        </>
      ),
      selector: (row) => row.created_at,
      sortable: true,
      style: {
        textAlign: "center",
      },
      width: "165px"
    },

  ];

  const resetPage = () => {
    window.location.reload();
  }

  return (
    <>
      <div
        className="basic"
        style={{ position: "relative", marginTop: "30px" }}
      >
        {" "}
      </div>

      <Container
        style={{ maxWidth: "max-content" }}>
        <div className="mb-3">
          <IconButton onClick={handleExport}>
            <FontAwesomeIcon icon={faFileExcel} style={{ width: '27px', height: '27px', color: '#243847' }} />
          </IconButton>
          <span style={{ paddingLeft: "30px", paddingRight: "15px" }}>
            <b> Start Date</b>
          </span>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            placeholder="Start Date"
            required
          />
          <span style={{ paddingLeft: "30px", paddingRight: "15px" }}>
            <b> End Date</b>
          </span>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            placeholder="End Date"
            required
          />
          <span style={{ paddingLeft: "35px", paddingRight: "10px" }}></span>
          <Button
            onClick={filterData}
            style={{
              backgroundColor: '#243847', borderColor: '#243847'
              , height: "30px", width: "50px", padding: "0", fontSize: "13px"
            }}
          >Search</Button>

          <Tooltip title="Refresh" placement="right">
            <RefreshIcon
              onClick={resetPage}
              style={{
                marginLeft: '20px',
                cursor: 'pointer',
                fontSize: '30px',
                color: '#243847',
              }}
            />
          </Tooltip>
        </div>
        <DataTable
          className="data-table-container"
          columns={columns}
          data={filteredData.length ? filteredData : [{}]}
          pagination
          responsive
          highlightOnHover
          dense
        // noDataComponent={
        //   <div style={{ padding: '20px' }}>No data available</div>
        // }
        />
      </Container>
    </>
  );
}
