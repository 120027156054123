import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { API } from "../api/API";
import Logo from "../images/logo2.png";
// import 'pagedjs/dist/interface.min.css';
// import paged from "pagedjs"; // Import if using npm



export default function LoadingSlipPrint() {

  const [loadingSlipData, setLoadingSlipData] = useState("");
  const [date, setdate] = useState("");
  const location = useLocation();
  const api = new API();
  console.log(loadingSlipData, "LoadingSlipData");

  useEffect(() => {
    // Parse the query parameters to get the loadingSlipID
    const searchParams = new URLSearchParams(location.search);
    const loadingSlipID = searchParams.get('loadingSlipID');
    const createdAt = searchParams.get('createdAt');
    setdate(createdAt ? `${createdAt.split(' ')[0].split('-')[2]}-${createdAt.split(' ')[0].split('-')[1]}-${createdAt.split(' ')[0].split('-')[0]}` : '')

    // Fetch loading slip data from the API
    const fetchLoadingSlipData = async () => {
      try {
        if (loadingSlipID) {
          const response = await api.loading_slip_print_transaction_get(loadingSlipID);
          console.log(response, "response");

          if (response) {
            setLoadingSlipData(response);
          } else {
            console.error("No data found in the response.");
          }
        } else {
          console.error("No loadingSlipID found in query params.");
        }
      } catch (error) {
        console.error("Error fetching loading slip data:", error);
      }
    };

    fetchLoadingSlipData();
  }, [location.search]);

  useEffect(() => {
    if (loadingSlipData) {
      // paged.polyfill();
      // console.log(paged.polyfill());
      
      window.print();
      window.close();
    }
  }, [loadingSlipData]);

  // useEffect(() => {
  //   if (loadingSlipData) {
  //     const pagedScript = document.createElement("script");
  //     pagedScript.src = "https://www.pagedjs.org/js/paged.polyfill.e35b32228f7c0ce277c05a9b4d3db5c5.js";
  //     pagedScript.type = "module";
  //     document.body.appendChild(pagedScript);

  //   pagedScript.onload = () => {
  //     // Initialize Paged.js when the script is loaded
  //     if (window.PagedPolyfill) {
  //       window.print();
  //       window.PagedPolyfill.preview();
  //           window.close();

  //     }
  //   };

  //   // Clean up the script when component unmounts
  //   return () => {
  //     document.body.removeChild(pagedScript);
  //   };
  // }
// }, [loadingSlipData]);

  
  const { header_data, content_data, footer_data } = loadingSlipData || {
    header_data: [0],
    content_data: [],
    footer_data: []
  };


  return (
    <div className="loadingslipprint">
      <div className="packing-list-container">
        <div className="header">
          <div className="logo">
            <img src={Logo} alt="Logo" style={{ width: '160px', height: '30px', position: 'relative' }} />
            <h1>LUFTEK ENGINEERING PVT LTD</h1>
          </div>
          <div className="company-details">

            <p>Plot No. A-1/1, Industrial Park - Ph II, Cheyyar-Kancheepuram Road, Kunnavakkam, Cheyyar SIPCOT, Tiruvannamalai District, Tamil Nadu - 631701.</p>
          </div>
        </div>

        <h2 style={{ textAlign: 'center', fontSize: '24px', margin: '5px', fontWeight: 'bold' }}>PACKING LIST</h2>

        <table className="packing-list-table">
          <tbody>
            <tr>
              <th>CLIENT</th>
              <td colSpan='3'>{header_data[0].client}</td>
              <th>DATE</th>
              <td>{date}</td>
            </tr>
            <tr>
              <th rowSpan='2'>PROJECT</th>
              <td colSpan='3' rowSpan='2'>{header_data[0].project}</td>
              <th>VEHICLE No.</th>
              <td>{header_data[0].vehicle_number}</td>
            </tr>
            <tr>
              <th>JTN No.</th>
              <td>{header_data[0].job_transaction_number}</td>
            </tr>
            <tr>
              <th>REFERENCE</th>
              <td colSpan='3'>{header_data[0].reference}</td>
              <th>UNIT QTY.</th>
              <td>1</td>
            </tr>
            <tr>
              <th colSpan='3' style={{ textAlign: 'left' }}><span style={{ padding: ' 0 20px' }}>SALES ORDER -NO.</span>  <span style={{ paddingLeft: '40px' }}>{header_data[0].sales_order_number}</span></th>
              <th colSpan='3' style={{ textAlign: 'left' }}><span style={{ padding: ' 0 20px' }}>INVOICE No.</span> <span style={{ paddingLeft: '30px' }}></span></th>
            </tr>
            <tr>
              <th>CONDITION</th>
              <td>CKD <input type="checkbox" /></td>
              <td>SKD <input type="checkbox" /></td>
              <td>ASS <input type="checkbox" /></td>
              <th>DISPATCH</th>
              <td colSpan="3">FULL / PART</td>
            </tr>
          </tbody>
        </table>

        <table className="item-list-table">
          <thead>
            <tr>
              <th>SL. NO.</th>
              <th>PACKET NUMBER/BOX NUMBER</th>
              <th>ITEM DESCRIPTION</th>
              <th>VERIFIED BY</th>
            </tr>
          </thead>
           <tbody>
            {content_data.map((item, index) => (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>{item.pack_box_number}</td>
                <td>{item.part_description}</td>
                <td></td>
              </tr>
            ))}
          </tbody>

        </table>
        <table className="item-list-table">
          <tr id="table-footer">
            <td colSpan="3">Total No. of Packets</td>
            <td>{footer_data}</td>
          </tr>
          <tr id="table-footer">
            <td colSpan="3">Total No. of Line Items</td>
            <td>{content_data.length}</td>
          </tr>
        </table>
        <div className="footer">
          <p>REMARKS - (PENDING FROM FACTORY):-</p>
          <p>Note: Please count the No. of packets and sign a copy of this packing list and send to factory for our records, if you find any discrepancy in the no. of packets.</p>
          <div className="signature-block">
            <div>PREPARED BY</div>
            <div>CHECKED BY</div>
            <div>RECEIVER NAME & SIGN</div>
          </div>
        </div>
      </div>
      <div className="jtn-footer">
          JTN No. - {header_data[0].job_transaction_number}
        </div>
    </div>
  );
}
