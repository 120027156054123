import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Button, Container } from 'react-bootstrap'; // Optional: for the "Create" button
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API } from '../api/API';
import { width } from '@fortawesome/free-solid-svg-icons/fa0';
import EditNoteIcon from '@mui/icons-material/EditNote';
import EditLogo from '../images/editlogo.png';
import { IconButton, Menu, MenuItem } from '@mui/material';

const UserPage = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const api = new API();
  const [data, setData] = useState([])
  const navigate = useNavigate();
  useEffect(() => {
    async function get() {
      const response = await api.get_users_with_roles();

      setData(response)
    }
    get();
  }, [])

  // Check if the user is an admin
  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (userDetails && userDetails.username === 'admin1') {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, []);


  const [anchorEl, setAnchorEl] = useState(null);
  const [openRowId, setOpenRowId] = useState(null);

  const handleClick = (event, rowId) => {
    setAnchorEl(event.currentTarget);
    setOpenRowId(rowId);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenRowId(null);
  };

  const columns = [
    { name: 'S.No.', selector: (row, index) => index + 1, sortable: false, width: '70px' },
    {
      name: 'Username', selector: row => row.username, sortable: true, cell: row => (
        <div title={row.username} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {row.username}
        </div>
      ),
    },
    {
      name: 'Name', selector: row => row.name, sortable: true,
      cell: row => (
        <div title={row.name} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {row.name}
        </div>
      ),
    },
    {
      name: 'Email', selector: row => row.email, sortable: true,
      cell: row => (
        <div title={row.email} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {row.email}
        </div>
      ),
    },
    { name: 'Role', selector: row => row.roles, sortable: true },
    { name: 'Status', selector: row => row.status, sortable: true },
    { name: 'Created At', selector: row => row.created_at, sortable: true, width: "180px" },
    // { name: 'Updated By', selector: row =>new Date(row.updated_by).toLocaleDateString(), sortable: true },

    // Conditionally render the 'Action' column only if isAdmin is true
    //...(isAdmin ? [
    {
      name: 'Action',
      cell: row => (
        <>
          <IconButton
            onClick={(event) => handleClick(event, row.user_id)}
            color="inherit"
            disabled={Array.isArray(row.roles) && row.roles.includes('Admin')}
          >
            <EditNoteIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={openRowId === row.user_id}
            onClose={handleClose}
            PaperProps={{
              style: {
                width: 120,
                height: "max-content",
              },
            }}
          >
            <MenuItem onClick={() => { handleEditClick(row.user_id); handleClose(); }} style={{ fontSize: '0.75rem' }}>
              Edit
            </MenuItem>
            {isAdmin && <MenuItem onClick={() => { handleForgotClick(row.user_id); handleClose(); }} style={{ fontSize: '0.75rem' }}>
              Reset Password
            </MenuItem>}
          </Menu>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "100px"
    }
    //] : [])

  ];

  const handleCreateClick = () => {
    navigate("/register");
  };

  const handleEditClick = (userid) => {
    navigate(`/edit-user-role/${userid}`);
  };

  const handleForgotClick = (userid) => {
    navigate(`/forgot-password/${userid}`);
  };

  const handleBackSubmit = () => {
    navigate("/");
  };

  return (
    <div className='basic' style={{ position: "relative", marginTop: "40px" }}>
      <Container className='role-container' style={{ width: "100%" }}>
        <DataTable
          className="data-table-container"
          columns={columns}
          data={data}
          pagination
          highlightOnHover
          striped
        />
      </Container>

      <Button
        variant="dark"
        onClick={handleBackSubmit}
        type="button" // Change to button to avoid submitting the form
        className="user-register-back-btn"
        size="sm"
        style={{ backgroundColor: '#243847', borderColor: '#243847', position: 'relative', left: '78px' }}
      >
        Back
      </Button>

      {/* {isAdmin && (
        <> */}
      <Button
        style={{ backgroundColor: '#243847', borderColor: '#243847' }}
        variant="dark"
        onClick={handleCreateClick}
        className="user-page-create-btn"
        size="sm"
      >
        Create
      </Button>
      {/* </>
    )} */}
    </div>
  );
};

export default UserPage;
